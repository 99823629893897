/**/






.product-sort ul {
    font-size: 13px;
    width: 130px;
    max-width: none;
	top:100%
    
}


@media all and (max-width: 991px) {
	.product-sort ul {
        left: auto;
        /* right: -10px;
        top: calc(100% + 15px); */
    }
}


@media all and (min-width: 992px) {
	.product-sort ul {
        left: auto;
        top: calc(100% + 51px);
    }
	.product-category .main-heading .dropdown-select{
		top:17px
	}
}




.product-category .empty-products {
    display: block;
    position: relative;
    height: 200px;    
    width: 100% !important;
}

.product-category .empty-products span {
    position: absolute;
    width: 80%;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}










/* .product-category .products-wrapper {
    border-top: 1px solid #EAEBED;
    border-bottom: 1px solid #EAEBED;
} */


.product-category .products-wrapper .product {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #EAEBED;
}

.products-wrapper .product .product-content {
    width: 100%;
	padding-left: 8%;
	padding-right: 8%;
}

.product-category .products-wrapper .product .simple-btn-wrapper {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: auto;
    margin-bottom: 0;
}

.product-category .products-wrapper .product .name {
    font-weight: 600;
}





@media all and (max-width: 575px) {
    .product-category .products-wrapper .product {
        width: 50%;
    }
	.product-category .products-wrapper .product:not(:nth-child(2n)){
        border-right: 1px solid #EAEBED;
    }
}


@media all and (min-width: 576px) and (max-width: 991px) {
    .product-category .products-wrapper .product {
        width: 33.3333%;
    }
	.product-category .products-wrapper .product:not(:nth-child(3n)){
        border-right: 1px solid #EAEBED;
    }
}


@media all and (min-width: 992px) {    
    .product-category .products-wrapper .product {
        width: 25%;
    }
	.product-category .products-wrapper .product:not(:nth-child(4n)){
        border-right: 1px solid #EAEBED;
    }
}



.product-category .pagination {
	justify-content: space-between;
}


.product-category .main-heading {
	position: relative;
}

.product-category .main-heading .dropdown-select {
	position: absolute;
	right: 0;
	width: 100px;
}

@media all and (max-width: 991px) {
	.product-category .main-heading {
		padding: 15px 0;
		border-bottom: none;
	}
	.product-category .main-heading .dropdown-select {
		position: relative;
	}
}


@media (min-width: 992px) {
	/* .product-category .main-heading {
		padding-right: 15px;
	}	 */
}






.product-category .main-heading .view-modes {
	display: flex;
}


.product-category .main-heading .view-modes .mode.active path {
	fill: #EF780B;
}

.product-category .main-heading .view-modes .mode path {
	fill: #B3B3B3;
}


.product-category .main-heading .view-modes .mode {
	cursor: pointer;
}


@media all and (max-width: 575px) {
	.product-category .main-heading .view-modes .mode:not(:last-child) {
		margin-right: 10px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.product-category .main-heading .view-modes .mode:not(:last-child) {
		margin-right: 12.5px;
	}
}


@media all and (min-width: 768px) {
	.product-category .main-heading .view-modes .mode:not(:last-child) {
		margin-right: 15px;
	}
}







.product-category .products-wrapper {
	margin-bottom: 0;
}



@media all and (max-width: 575px) {
	.product-category .pagination:not(.only-page) {
		margin-top: 30px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.product-category .pagination:not(.only-page) {
		margin-top: 30px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.product-category .pagination:not(.only-page) {
		margin-top: 35px;
	}
}


@media all and (min-width: 992px)  {
	.product-category .pagination:not(.only-page) {
		margin-top: 40px;
	}
}











/*--------- Grid Mode ---------*/


.grid-wrapper .product {
	flex: 0 0 auto;
	overflow: hidden;
	border-right: none;
	border-top: none;
}

.grid-wrapper .product:first-child {
	border-left: none;
}

.grid-wrapper .product:last-child {
	border-right: none ;
}








.grid-wrapper .product .name {
	position: unset;
}

.grid-wrapper .product .name span {
	width: 100%;
	padding-right: 0;
	display: block;
}


.grid-wrapper .product .tags {
	position: absolute;
	top: 20px;
	right: 0;
}














@media all and (max-width: 991px) {
	.grid-wrapper .product .name .icon {
		display: none;
	}
}






.grid-wrapper .product .name {
	width: 100%;
	line-height: 1.3em;
}






.grid-wrapper .product .product-quantity .contain-row {
	justify-content: center;
}

.grid-wrapper .product .product-quantity .quantity-input {
	width: 100%;
	margin-bottom: 7.5px;
}



.grid-wrapper .product .product-quantity .w-btn span {
	display: none;
}


@media all and (max-width: 575px) {
	.grid-wrapper .product .quantity-btns .icon {
		height: 30px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.grid-wrapper .product .quantity-btns .icon {
		height: 31px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.grid-wrapper .product .quantity-btns .icon {
		height: 32px;
	}

}











/*--------- Product Name ---------*/


.product .name {
	font-weight: 500;
	margin-bottom: 0;
	transition: all ease-in-out .3s;
	line-height: 1.3em;
	display: block;	
}

.product .name span {
	color: #242529;
	transition: all ease-in-out .3s;
}

.product:hover .name span {
	color: #6D7199;
}

.product .id {
	color: #242529;
	opacity: .5;
}


.product .name {
	color: #000000;
	transition: all ease-in-out .3s;
}

.product a:hover .name {
	color: #FB9B51;
}




@media all and (max-width: 767px) {
	.product .name-id {
		margin-bottom: 10px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.product .name-id {
		margin-bottom: 15px;
	}
}


@media all and (min-width: 992px) {
	.product .name-id {
		margin-bottom: 10px;
		font-size: 14px;
	}
}







.products-wrapper li {
	display: block;
}








/*-------- 0. Product --------*/


.products-wrapper .product {
	position: relative;
	transition: all ease-in-out .3s;
	width: 50%;
}




.product .span-border {
	position: absolute;
	transition: all ease-in-out .5s;
	z-index: 1000;
}



.product .span-border.top, 
.product .span-border.bottom {
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border: 1px solid rgba(0,0,0,0);
}


.product:hover .span-border.top, 
.product:hover .span-border.bottom {
	border-left: 1px solid #FB9B51;
	border-right: 1px solid #FB9B51;
}


.product .span-border.top {	
	top: 0; 
}

.product:hover .span-border.top {
	border-top: 1px solid #FB9B51;
}


.product .span-border.bottom {
	bottom: 0; 
}

.product:hover .span-border.bottom {
	border-bottom: 1px solid #FB9B51;	
}






.product .span-border.left,
.product .span-border.right {
	top: 50%;
	transform: translateY(-50%);
	background: #FB9B51;
	width: 1px;
	height: 0;
}

.product .span-border.left {
	left: 0;
}


.product .span-border.right {
	right: 0;
}







@media all and (max-width: 575px) {
	.product .span-border.top {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	
	.product .span-border.bottom {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	
	.product:hover .span-border.top, 
	.product:hover .span-border.bottom {
		width: 100%;
		height: 5px;
	}
	
	.product:hover .span-border.left,
	.product:hover .span-border.right {
		height: calc(100% - 10px);
	}	
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.product .span-border.top {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	
	.product .span-border.bottom {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	
	.product:hover .span-border.top, 
	.product:hover .span-border.bottom {
		width: 100%;
		height: 10px;
	}
	
	.product:hover .span-border.left,
	.product:hover .span-border.right {
		height: calc(100% - 20px);
	}	
}






@media all and (min-width: 768px) {
	.product .span-border.top {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	
	.product .span-border.bottom {
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
	}
	
	.product:hover .span-border.top, 
	.product:hover .span-border.bottom {
		width: 100%;
		height: 15px;
	}
	
	.product:hover .span-border.left,
	.product:hover .span-border.right {
		height: calc(100% - 30px);
	}		
}










.product {
	display: block;
	background: #FFF;
	transition: all ease-in-out .3s;
}









/*-------- 1. Product Featured Image --------*/




.product .featured-wrapper {
	position: relative;
    transition: all ease-in-out .3s; 
    /* padding: 20px; */
}


.list-wrapper .product .featured-wrapper .img-wrapper {
	border: 1px solid rgba(0,0,0,.05);
}


/* .grid-wrapper .product .featured-wrapper .img-wrapper {
	border-bottom: 1px solid rgba(0,0,0,.05);
} */


.product .featured-wrapper .img-wrapper {
    position: relative;
    overflow: hidden;
    transition: all ease-in-out .3s;
}

.product .featured-wrapper .img-wrapper:after {
    content: "";
    display: block;
	padding-top: 100%;
	border-radius: 0;
}

.product .featured-wrapper .img-wrapper img {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    /* transition: all ease-in-out 1s; oanh changed */
    transition: all ease-in-out 1s;
    border-radius: 5px;
}


.product .featured-wrapper .img-wrapper:hover img {
	object-position: bottom;
}





.grid-wrapper .product .img-wrapper {
	margin-bottom: 20px;
}






/*-------- 2. Product Name --------*/







/*-------- 3. Product Id --------*/






/*-------- 4. Product Price --------*/



.product .price {
	width: 100%;
}

@media all and (max-width: 991px) {
	.product .price {
        margin-bottom: 10px;
    }
}

@media all and (min-width: 992px) {
	.product .price {
        margin-bottom: 15px;
    }    
}

@media (min-width:1200px){
	.products-wrapper .product{
		padding: 0;
		/* width: 275px !important; */
		margin-right: 29px;
	}
	.products-wrapper .product:nth-of-type(4n){
        margin-right: 0 !important;
    }
}
@media (min-width:1200px) and (max-width:1399px){
	.products-wrapper .product{
		width: 22.8% !important;
	}
}
.justify-content-center{
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif;
}



.product .price .member {
	color: #000;
}


.product .price .prices {
    margin-bottom: 5px;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif;
	font-weight: 800;
}

.product .price .pvs .type:not(:last-child) {
    margin-right: 20px;
}


/*-------- 5. Product Info Btns --------*/


.product .info-btns {
	margin-top: 20px;
	justify-content: start;
	padding-left: 8%;
    padding-right: 8%;
}

.product .enter-icon {
	width: 25px;
}
.product .info-btns .info-btn {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	transition: all ease-in-out .5s;
	background: #CDD1DC;
}
.grid-wrapper .product .enter-shop {
	display: flex;
	justify-content: start;
	color: #2A2E46;
	font-weight: bold;
}
.grid-wrapper .product .enter-icon {
	margin-left: 15px;
}

@media all and (max-width: 575px) {
	.product .info-btns .info-btn {
		border-radius: 5px;
	}
	
}


@media all and (min-width: 576px) {
	.product .info-btns .info-btn {
		border-radius: 50%;
	}
	
}














.product:hover .info-btns .info-btn {
	background: #8D96B1;
}

.product .info-btns .info-btn:hover {
	background: #596B86;
	box-shadow: 0px 5px 10px rgba(0,0,0,.2);
}

.product .info-btns .info-btn:not(:last-child) {
	margin-right: 5px;
}



@media all and (max-width: 991px) {
	.product .info-btns .info-btn img {
		height: 30px;
	}
}


@media all and (min-width: 992px) {
	.product .info-btns .info-btn img {
		height: 32px;
	}
}









/*---- All Products ----*/
/*
	0. Dropdown Bar
	1. Featured Image
	2. Product Name
	3. Product Id
	4. Product Price
*/



@media all and (max-width: 991px) {
	#all-products .products-wrapper {
		margin-bottom: 30px;
	}
}


/*---- All Products | 0. Dropdown Bar ----*/

#all-products .mobile-dropdown-bar {
	display: flex;
}

@media all and (max-width: 991px) {
	#all-products .mobile-dropdown-bar {
		display: flex;
	}
}

@media all and (min-width: 992px) {
	#all-products .mobile-dropdown-bar {
		display: none;
	}
}


#all-products .mobile-dropdown-bar .cate-label {
	display: flex;
	margin-right: 10px;
}

#all-products .mobile-dropdown-bar .cate-label span {
	margin: auto;
}



#all-products .mobile-dropdown-bar .btn-open-sub {
	transition: all ease-in-out .3s;
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
}

#all-products .mobile-dropdown-bar .btn-open-sub.opened {
	transform: rotate(135deg);
}




/*---- All Products | 1. Featured Image ----*/

#all-products .product .featured-wrapper {
	margin-bottom: 20px;
}




/*---- All Products | 2. Product Name ----*/





/*---- All Products | 3. Product Id ----*/





/*---- All Products | 4. Product Price ----*/

#all-products .product .price div:not(:last-child){
	margin-bottom: 2.5px;
}

.login #all-products .product .price .regular {
	font-weight: bold;
}
	




@media all and (max-width: 575px) {
	.login #all-products .product .price .regular {
		font-size: 13px;
	}
	.logged #all-products .product .price .regular {
		font-size: 12px;
	}
	.logged #all-products .product .price .sale {
		font-size: 14px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.login #all-products .product .price {
		font-size: 14px;
	}
	.logged #all-products .product .price .regular {
		font-size: 13px;
	}
	.logged #all-products .product .price .sale {
		font-size: 15px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.login #all-products .product .price {
		font-size: 15px;
	}
	.logged #all-products .product .price .regular {
		font-size: 14px;
	}
	.logged #all-products .product .price .sale {
		font-size: 16px;
	}
}


@media all and (min-width: 992px) {
	.login #all-products .product .price {
		font-size: 16px;
	}
	.logged #all-products .product .price .regular {
		font-size: 14px;
	}	
	.logged #all-products .product .price .sale {
		font-size: 17px;
	}
}


























/*----- Page All Products -----*/

#page-all-products #all-products .heading {
	justify-content: center;
}


#page-all-products #all-products .heading h2 {
	border-bottom: none;
	margin-bottom: 10px;
	text-align: center;
}


@media all and (min-width: 992px) {
	#page-all-products #all-products .heading h2 {
		margin-bottom: 30px;
	}
}






































@media all and (max-width: 575px) {
	#all-products .products-wrapper .product {
		width: calc(50% - 5px);
		margin-right: 10px;
		margin-top: 15px;
	}
	#all-products .products-wrapper .product:nth-child(2n) {
		margin-right: 0;
	}
	#all-products .products-wrapper .product:nth-child(1),	
	#all-products .products-wrapper .product:nth-child(2){
		margin-top: 0;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#all-products .products-wrapper .product {
		width: calc(50% - 10px);
		margin-right: 20px;
		margin-top: 20px;
	}
	#all-products .products-wrapper .product:nth-child(2n) {
		margin-right: 0;
	}
	#all-products .products-wrapper .product:nth-child(1),	
	#all-products .products-wrapper .product:nth-child(2){
		margin-top: 0;
	}
}




@media all and (min-width: 768px) and (max-width: 991px) {
	#all-products .products-wrapper .product {
		width: calc(33.3% - 15px);
		margin-right: 20px;
		margin-top: 20px;
	}
	#all-products .products-wrapper .product:nth-child(3n) {
		margin-right: 0;
	}
	#all-products .products-wrapper .product:nth-child(1),	
	#all-products .products-wrapper .product:nth-child(2),
	#all-products .products-wrapper .product:nth-child(3) {
		margin-top: 0;
	}
}





@media all and (min-width: 992px) and (max-width: 1199px) {
	#all-products .products-wrapper .product {
		width: calc(25% - 15px);
		margin-right: 20px;
		margin-top: 20px;
	}
	#all-products .products-wrapper .product:nth-child(4n) {
		margin-right: 0;
	}
	#all-products .products-wrapper .product:nth-child(1),	
	#all-products .products-wrapper .product:nth-child(2),
	#all-products .products-wrapper .product:nth-child(3),
	#all-products .products-wrapper .product:nth-child(4) {
		margin-top: 0;
	}	
}


@media all and (min-width: 1200px) {
	#all-products .products-wrapper .product {
		width: calc(25% - 22.5px);
		margin-right: 30px;
		margin-top: 50px;
	}
	#all-products .products-wrapper .product:nth-child(4n) {
		margin-right: 0;
	}
	#all-products .products-wrapper .product:nth-child(1),	
	#all-products .products-wrapper .product:nth-child(2),
	#all-products .products-wrapper .product:nth-child(3),
	#all-products .products-wrapper .product:nth-child(4) {
		margin-top: 0;
	}
}










@media all and (max-width: 575px) {
	.product {
		padding-bottom: 20px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.product {
		padding-bottom: 22.5px;
	}
}




@media all and (min-width: 768px) and (max-width: 991px) {
	.product {
		padding-bottom: 25px;
	}
}




@media all and (min-width: 992px) {
	.product {
		padding-bottom: 30px;
	}
}











.product .tags .tag {
	color: #FFF;
	background: #7280BC;
	font-size: 12px;
	padding-left: 7.5px;
	padding-right: 7.5px;
	border-top-left-radius: 2.5px;
	border-bottom-left-radius: 2.5px;
}






.grid-wrapper .product a {
	display: block;
	text-align:center;
    width: 100%;
}













/*---- Set Product -----*/



#set-products .product {
	display: block;
}









#set-products .results .products-wrapper,
#set-products .results .products-wrapper li {
	margin-bottom: 0;
}


#set-products .results .products-wrapper li .product-content {
	padding: 0 20px 20px;
}




#set-products .product .featured-wrapper {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 10px;
}


#set-products .product .featured-wrapper .img-wrapper {
	position: absolute;
    width: 100%;
    height: 100%;
    background: #FFF;
    transition: all ease-in-out .5s;
    top: 0;
}

#set-products .product .featured-wrapper:after {
	content: "";
    display: block;
    padding-top: 100%;
}


#set-products .product .featured-wrapper .img-wrapper img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}





@media all and (max-width: 767px) {	
	#set-products .results {
		font-size: 14px;
	}	
	#set-products .results .products-wrapper li .name {
		font-size: 20px;
	}
}
 




@media all and (max-width: 575px) {
	
}


@media all and (min-width: 576px) and (max-width: 767px) {
	
}


@media all and (min-width: 768px) and (max-width: 991px) {
	
}


@media all and (min-width: 992px) {
	#set-products .results .products-wrapper li .name {
		margin-bottom: 10px;
	}
}











#set-products .results .products-wrapper li .cate-label {
	color: rgba(0, 0, 0, 0.5);
}





#set-products .results .products-wrapper li {
	text-align: center;
}











#set-products .results .products-wrapper li .cate-label {
	color: rgba(0, 0, 0, 0.5);
	margin-bottom: 10px;
}





@media all and (min-width: 576px) {
	#set-products .results .products-wrapper .product .product-dscr {
		font-size: 14px;
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
}







#set-products .product .price {
	margin-bottom: 15px;
}





@media all and (max-width: 575px) {
	#set-products .product .price .sale {
		font-size: 14px;
	}
	.logged #set-products .product .price .regular {
		font-size: 13px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#set-products .product .price .sale {
		font-size: 15px;
	}
	.logged #set-products .product .price .regular {
		font-size: 14px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#set-products .product .price .sale {
		font-size: 16px;
	}
	.logged #set-products .product .price .regular {
		font-size: 15px;
	}
}


@media all and (min-width: 992px) {
	#set-products .product .price .sale {
		font-size: 17px;
	}
	.logged #set-products .product .price .regular {
		font-size: 16px;
	}	
}



#set-products .product:hover .name {
	color: #FF866B;
}








#set-products .results li .rd-btn {	
	justify-content: center;
	
	max-width: 135px;
	margin-left: auto;
	margin-right: auto;
	padding: 8px 30px;
	background: #F88125;
	border-radius: 20px;
	transition: all ease-in-out .5s;
}


#set-products .results li .rd-btn {
	margin-bottom: 20px;
}



#set-products .results li .rd-btn span {
	color: #fff;
	padding-right: 5px;
}

#set-products .results li .rd-btn .chevron-right path {
	fill: #fff;
	transition: all ease-in-out .5s;
}



#set-products .results li .rd-btn:hover {
	opacity: .8;
}


#set-products .results li .rd-btn:hover span {
	color: #FFF;
}

#set-products .results li .rd-btn:hover .chevron-right path {
	fill: #FFF;
}



























