



/*------- 1. Intro -------*/
/*------- 2. Features -------*/
/*------- 3. About Us -------*/
/*------- 4. Set Product -------*/
/*------- 5. CTA -------*/
/*------- 6. Videos -------*/
/*------- 7. Notices -------*/







/*------- 1. Intro -------*/

@media all and (max-width: 767px) {
	#st-intro .slide a {
		/* min-height: 240px; */
		position: relative;
		overflow: hidden;
	}
	#st-intro .slide a img {
		/* position: absolute; */
		/* width: auto;
    height: auto;
    max-height: 100%; */
		/* height: 100%;
		width: auto;
		left: 50%;
		transform: translateX(-50%); */
	}	
}



#st-intro .featured-slider.on .slider-dots div span {
	
}


#st-intro .featured-slider.on .slider-dots div:not(.active) span {
	opacity: .5;
}







@media all and (max-width: 575px) {
	#st-intro .featured-slider.on .slider-dots div span {
		width: 7px;
		height: 7px;
	}
	#st-intro .featured-slider.on .slider-dots div.active span {
		width: 28px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-intro .featured-slider.on .slider-dots div span {
		width: 8px;
		height: 8px;
	}
	#st-intro .featured-slider.on .slider-dots div.active span {
		width: 32px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-intro .featured-slider.on .slider-dots div span {
		width: 9px;
		height: 9px;
	}
	#st-intro .featured-slider.on .slider-dots div.active span {
		width: 36px;
	}
}


@media all and (min-width: 992px) {
	#st-intro .featured-slider.on .slider-dots div span {
		width: 10px;
		height: 10px;
	}
	#st-intro .featured-slider.on .slider-dots div.active span {
		width: 40px;
	}
}
















/*------- 2. Features -------*/


@media all and (max-width: 575px) {
	#st-features {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-features {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-features {
		padding-top: 90px;
		padding-bottom: 90px;
	}
}


@media all and (min-width: 992px) {
	#st-features {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}



/* #st-features .container {
	padding-top: 0;
	padding-bottom: 0;
}

 */





#st-features .col-feature {
	position: relative;
	text-align: center;
	padding-left: 3%;
	padding-right: 3%;	
}


@media all and (max-width: 575px) {
	#st-features .col-feature.certification, 
	#st-features .col-feature.business-information {
		margin-bottom: 40px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-features .col-feature.certification, 
	#st-features .col-feature.business-information {
		margin-bottom: 50px;
	}
}


















@media all and (min-width: 768px) {
	#st-features .col-feature:not(:last-child) {
		border-right: 1px solid rgba(0,0,0,.05);
	}
}



#st-features .col-feature .icon svg {
	height: 100%;
}


#st-features .col-feature .icon svg path {
	fill: #2A2E46;
	transition: all ease-in-out .3s;
}


#st-features .col-feature:hover .icon svg path {
	fill: #EF780B;
}




@media all and (max-width: 575px) {
	#st-features .col-feature .icon {
		height: 50px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-features .col-feature .icon {
		height: 55px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-features .col-feature .icon {
		height: 60px;
	}
}


@media all and (min-width: 992px) {
	#st-features .col-feature .icon {
		height: 70px;
	}
}









#st-features .col-feature h4 {
	color: #2A2E46;
	font-weight: 700;
	margin-bottom: 10px;	
	transition: all ease-in-out .3s;
}

#st-features .col-feature:hover h4 {
	color: #EF780B;
}


#st-features .col-feature p {
	color: #2A2E46;
	opacity: .5;
	margin-bottom: 0;
	transition: all ease-in-out .3s;
}










@media all and (max-width: 575px) {
	#st-features .col-feature .icon {
		margin-bottom: 22.5px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-features .col-feature .icon {
		margin-bottom: 25px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-features .col-feature .icon {
		margin-bottom: 27.5px;
	}
}


@media all and (min-width: 992px) {
	#st-features .col-feature .icon {
		margin-bottom: 30px;
	}
}










/*------- 3. About Us -------*/



@media all and (max-width: 575px) {
	#st-about-us .container {
		padding-bottom: 60px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-about-us .container {
		padding-bottom: 80px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-about-us .container {
		padding-bottom: 100px;
	}
}


@media all and (min-width: 992px) {
	#st-about-us .container {
		padding-bottom: 40px;
		border-bottom: 1px solid #2A2E46;
	}
}






#st-about-us .w-video {
	overflow: hidden;
	box-shadow: 0px 15px 20px rgba(0,0,0,.1);
}


#st-about-us .w-video:hover {
	box-shadow: 0px 25px 30px rgba(0,0,0,.15);
}




@media all and (min-width: 768px) and (max-width: 991px) {
	/* #st-about-us .w-video {
		border-top-right-radius: 70px;
		border-bottom-left-radius: 70px;
	} */
}


@media all and (min-width: 992px) {
	/* #st-about-us .w-video {
		border-top-right-radius: 80px;
		border-bottom-left-radius: 80px;
	} */

	#st-about-us .ytp-iframe {
		border-top-right-radius: 50px;
		border-bottom-left-radius: 50px;
	}
}


#st-about-us .w-video.onplay {
	border-radius: 0;
}

#st-about-us .video-wrapper {
	height: 350px;
}

#st-about-us .ytp-iframe {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
}




 
@media all and (max-width: 575px) {
	#st-about-us .col-video {
		margin-bottom: 40px;
	}
	#st-about-us .col-text {
		text-align: center;
		padding-left: 4%;
		padding-right: 4%;
	}
	#st-about-us .col-text .w-btn {
		margin-left: auto;
		margin-right: auto;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-about-us .col-video {
		margin-bottom: 50px;
	}
	#st-about-us .col-text {
		text-align: center;
	}
	#st-about-us .col-text .w-btn {
		margin-left: auto;
		margin-right: auto;
	}
}




@media all and (min-width: 768px) {
	#st-about-us .col-video {
		width: 44%;
		margin-right: 6%;
		border-top:1px solid rgba(0,0,0,.1);
	}
	#st-about-us .col-text {
		width: 44%;
		padding-left: 6%;
		border-left: 1px solid #2A2E46;
	}
	#st-about-us .col-text::after {
		content : "";
		position: absolute;
		height  : 1px;
		width   : 40%;  /* or 100px */
		border-top:1px solid rgba(0,0,0,.1);
	}
}






#st-about-us .col-text .heading {
	margin-bottom: 20px;
}


#st-about-us .col-text .heading h2 {
	font-weight: bold;
}

#st-about-us .col-text .heading span {
	opacity: .5;
}

#st-about-us .col-text p {
	margin-bottom: 30px;
}

#st-about-us .col-text .w-btn {
	min-width: 150px;
	border-radius: 100px;
}



@media all and (max-width: 575px) {
	
}


@media all and (min-width: 576px) and (max-width: 767px) {
	
}


@media all and (min-width: 768px) and (max-width: 991px) {
	
}


@media all and (min-width: 992px) {
	#st-about-us .col-text {
		display: flex;
	}
	
	#st-about-us .col-text .content {
		margin-top: auto;
		margin-bottom: auto;
		padding-bottom: 30px;
	}
}







.elements {
	position: absolute;
	height: 100%;
	z-index: -1;	
	top: 0;
}

.elements .element {
	position: absolute;
	height: auto;
}







@media all and (max-width: 991px) {
	#st-about-us .elements {
		width: 100%;
		left: 0;
	}
	#st-about-us .elements .dots-vertical {
		width: 6%;
		max-width: 50px;
		left: 2%; 
		top: -10%;
	}
	#st-about-us .elements .dots-horizontal {
		width: 24%;
		max-width: 140px;
		right: -2%;
		bottom: 6%;
	}
}



@media all and (min-width: 992px) {
	#st-about-us .elements {
		max-width: 1200px;
		width: 92%;
		left: 50%;
		transform: translateX(-50%);
	}

	#st-about-us .elements .dots-vertical {
		width: 6%;
		left: -8%; 
		top: 8%;
	}
	
	#st-about-us .elements .dots-horizontal {
		width: 16%;
		right: 0;
		bottom: 6%;
	}
}



















/*------- 4. Product Sets -------*/


#st-product-sets .container {
	position: relative;
}


@media all and (max-width: 575px) {
	#st-product-sets .container {
		padding-bottom: 70px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-product-sets .container {
		padding-bottom: 80px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-product-sets .container {
		padding-bottom: 90px;
	}
}


@media all and (min-width: 992px) {
	#st-product-sets .container {
		padding-bottom: 100px;
	}
}






#st-product-sets .heading {
	text-align: center;
}

#st-product-sets .heading span {
	opacity: .5;
	max-width: 280px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

@media all and (max-width: 575px) {
	#st-product-sets .heading {
		margin-bottom: 30px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-product-sets .heading {
		margin-bottom: 40px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-product-sets .heading {
		margin-bottom: 50px;
	}
}


@media all and (min-width: 992px) {
	#st-product-sets .heading {
		margin-bottom: 60px;
	}
}







#st-product-sets .set {
	text-align: center;	
	background: #FFF;
}
	
#st-product-sets .set .w-btn span {
	color: #242529;
}

#st-product-sets .set:hover .w-btn span {
	color: #739E3C;
}


@media all and (max-width: 991px) {
	#st-product-sets .set:hover .w-btn.hover-icon-btn span {
		transform: translateX(0);
	} 

	#st-product-sets .set:hover .w-btn.hover-icon-btn .hover-icon {
		width: 0;
		opacity: 0;
	}
}





@media all and (min-width: 992px) {
	#st-product-sets .set:hover .w-btn.hover-icon-btn span {
		transform: translateX(-5px);
	} 

	#st-product-sets .set:hover .w-btn.hover-icon-btn .hover-icon {
		width: 18px;
		opacity: 1;
		transform: translateX(5px);
	}
}




#st-product-sets .set p {
	color: #242529;
	opacity: .5;
}
	
	






@media all and (max-width: 575px) {
	#st-product-sets .set {
		padding: 0 5px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-product-sets .set {
		padding: 0 7.5px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-product-sets .set {
		padding: 0 10px;
	}
}



@media all and (min-width: 992px) {
	#st-product-sets .set {
		width: 31%;		
		margin-bottom: 40px;
		transition: all ease-in-out .3s;
	}
	#st-product-sets .set:hover {
		transform: translateY(-20px);
	}
	#st-product-sets .set:not(:nth-child(3n)) {
		margin-right: 3.5%;
	}	
}









#st-product-sets .set a {
	display: block;
}



@media all and (max-width: 575px) {
	#st-product-sets .set a {
		overflow: hidden;
		border-radius: 20px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-product-sets .set a {
		overflow: hidden;
		border-radius: 25px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-product-sets .set a {
		overflow: hidden;
		border-radius: 30px;
	}
}


@media all and (min-width: 992px) {
	#st-product-sets .set {
		overflow: hidden;
		border-radius: 40px;
	}
	#st-product-sets .set:hover {
		box-shadow: 0px 5px 25px rgba(0,0,0,.1);
	}
	#st-product-sets .set a {
		padding-bottom: 40px;
	}	
}











@media all and (max-width: 575px) {
	#st-product-sets .featured-slider {
		padding-bottom: 40px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-product-sets .featured-slider {
		padding-bottom: 45px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-product-sets .featured-slider {
		padding-bottom: 50px;
	}
}



#st-product-sets .featured-slider .slider-dots {
	bottom: 0;
}



#st-product-sets .featured-slider .slider-dots .dot span {
	box-shadow: none;
	background: #E4E3E5;
}


#st-product-sets .featured-slider .slider-dots .dot.active span {
	width: 10px;
	background: #242529;
}

#st-product-sets .featured-slider .prev {
	left: 0;
}

#st-product-sets .featured-slider .next {
	right: 0;
}










#st-product-sets .elements {
	width: 100%;
	top: 0;
	left: 0;
}








@media all and (max-width: 991px) {	
	#st-product-sets .elements .circle-green-stripes {
		width: 6%;
		left: -3%; 
		top: -5%;
	}
	#st-product-sets .elements .triangle-orange {
		width: 25px;
		left: 10%;
		top: 10%;
	}
	#st-product-sets .elements .wave-green {
		width: 8%;
		right: 2.5%;
		bottom: 10%;
	}
	#st-product-sets .elements .circle-orange {
		width: 3%;
		right: -1%;
		top: 10%;
	}
	#st-product-sets .elements .rhombus-border {
		width: 20px;
		right: 15%;
		bottom: 10%;
	}
}



@media all and (min-width: 992px) {
	#st-product-sets .elements .circle-green-stripes {
		width: 6%;
		left: -3%; 
		top: 10%;
	}
	#st-product-sets .elements .triangle-orange {
		width: 30px;
		left: 7.5%;
		top: 35%;
	}
	#st-product-sets .elements .wave-green {
		width: 8%;
		right: 5%;
		top: 10%;
	}
	#st-product-sets .elements .circle-orange {
		width: 3%;
		right: -1%;
		top: 10%;
	}
	#st-product-sets .elements .rhombus-border {
		width: 40px;
		right: 5%;
		bottom: 10%;
	}
}












/*------- 5. CTA -------*/

#st-cta {
	position: relative;
	height: 420px;
	margin-bottom: 50px;
}

#st-cta .container {
	padding: 40px 0;
	height: 100%;
}

#st-cta .container .row {
	align-items: center;
	position: absolute;	
	bottom: 0;
}

#st-cta .container .content {
	background-color: #fff;
	padding: 40px;
}

#st-cta h4 {
	color: #2A2E46;
	padding-bottom: 10px;
	font-size: 32px;
}

@media all and (max-width: 575px) {
	#st-cta h4 {
		margin-bottom: 15px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-cta h4 {
		margin-bottom: 20px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-cta h4 {
		margin-bottom: 25px;
	}
}


@media all and (max-width: 991px) {
	#st-cta h4 {
		max-width: 90%;
	}
	#st-cta {
		position: none;
	}
}



@media all and (min-width: 992px) {
	#st-cta h4 {
		margin-bottom: 0;
	}
}






#st-cta .w-btn {
	border-radius: 100px;
	min-width: 110px;
	max-width: 110px;
	align-items: center;
	margin-top: 50px;
}


#st-cta .w-btn span {
	margin-right: 10px;
}

#st-cta .w-btn .icon {
	height: 16px;
}

#st-cta .w-btn .icon img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}






@media all and (max-width: 991px) {
	#st-cta .container .row {
		text-align: center;
		justify-content: center;
	}
}


@media all and (min-width: 992px) {


}





#st-cta .background {	
	position: absolute;
	background-image: url("/assets/img/home/element-1.png");
	width: 100%;
	height: 100%;
	overflow: hidden;
	top: 0; left: 0;
	z-index: -1;
}


@media all and (max-width: 575px) {
	#st-cta .background {
		display: block;
		position: absolute;
		background-image: url("/assets/img/home/element-2.png");
		width: 100%;
		height: 100%;
		overflow: hidden;
		top: 50; left: 50;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-cta .background {
		position: absolute;
		background-image: url("/assets/img/home/element-2.png");
		width: 100%;
		height: 100%;
		overflow: hidden;
		top: 50; left:50;
	}
}

@media all and (max-width: 991px) {
	#st-cta .container .row  {
		/* position: relative; */
	}
}












/*------- 6. Videos -------*/



#st-video {
    position: relative;
}

#st-video .standard-heading h2 {
	font-weight: 600;
}



#st-video .featured-slider .nav {
	border-radius: 0;
	background: rgba(0,0,0,.1);
}



#st-video .featured-slider .nav:hover {
	background: rgba(0,0,0,.2);
}




@media all and (max-width: 575px) {
	#st-video .container {
		padding: 70px 0;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-video .container {
		padding: 80px 0;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-video .container {
		padding: 90px 0;
	}
}


@media all and (min-width: 992px) {
	#st-video .container {
		padding: 100px 0;
	}
}





#st-video .slide .w-youtube-video {
    box-shadow: 0px 5px 10px rgba(0,0,0,.1);
	margin-bottom: 20px;
	transition: all ease-in-out .3s;
}

#st-video .slide .w-youtube-video:hover {
    box-shadow: 0px 5px 10px rgba(0,0,0,.2);
}




@media all and (max-width: 575px) {
	#st-video .container {
		width: calc(92% + 10px);
	}
	#st-video .standard-heading {
		padding-left: 5px;
		padding-right: 5px;
	}

	#st-video .featured-slider .slide {
		padding: 0 5px;
	}
	#st-video .featured-slider .prev {
		left: 5px;
	}

	#st-video .featured-slider .next {
		right: 5px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-video .container {
		width: calc(92% + 15px);
	}
	#st-video .standard-heading {
		padding-left: 7.5px;
		padding-right: 7.5px;
	}
	#st-video .featured-slider .slide {
		padding: 0 7.5px;
	}
	#st-video .featured-slider .prev {
		left: 7.5px;
	}
	#st-video .featured-slider .next {
		right: 7.5px;
	}
}


@media all and (min-width: 768px) {
	#st-video .container {
		width: calc(92% + 20px);
		max-width: 1220px;
	}
	#st-video .standard-heading {
		padding-left: 10px;
		padding-right: 10px;
	}
	#st-video .featured-slider .slide {
		padding: 0 10px;
	}	
	#st-video .featured-slider .prev {
		left: 10px;
	}
	#st-video .featured-slider .next {
		right: 10px;
	}
}













@media all and (max-width: 575px) {
	#st-video .featured-slider .nav {
		width: 20px;
		height: 20px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	
}


@media all and (min-width: 768px) and (max-width: 991px) {
	
}


@media all and (min-width: 992px) {
	#st-video .featured-slider .nav {
		width: 30px;
		height: 30px;
	}
}










#st-video .elements {
	width: 100%;
}


@media all and (max-width: 991px) {
	#st-video .elements .dots-horizontal {
		width: 12.5%;
		min-width: 100px;
		bottom: 15%;
		left: -5%;
	}
	#st-video .elements .triangle-green {
		width: 5%;
		max-width: 16px;
		bottom: 10%;
		right: 15%;
	}
}


@media all and (min-width: 992px) {
	#st-video .elements .dots-horizontal {
		width: 10%;
		bottom: 15%;
		left: -5%;
	}
	#st-video .elements .triangle-green {
		max-width: 20px;
		width: 5%;
		bottom: 15%;
		right: 5%;
	}
}






#st-video .slide .post-title {
	margin-bottom: 2px;
}

#st-video .slide .post-info {
	opacity: .5;
}


@media all and (max-width: 767px) {
	#st-video .slide .post-title {
		font-size: 14px;
	}
	#st-video .slide .post-info {
		font-size: 12px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-video .slide .post-title {
		font-size: 15px;
	}
	#st-video .slide .post-info {
		font-size: 13px;
	}
}


@media all and (min-width: 992px) {
	#st-video .slide .post-title {
		font-size: 16px;
	}
	#st-video .slide .post-info {
		font-size: 14px;
	}
}























/*------- 7. Notice -------*/

#st-notice .standard-heading h2 {
	font-weight: 600;
}	

@media (max-width: 575px) {
	#st-notice {
		margin-bottom: 80px;
	}	
}


@media (min-width: 576px) and (max-width: 767px) {
	#st-notice {
		margin-bottom: 100px;
	}	
}


@media (min-width: 768px) and (max-width: 991px) {
	#st-notice {
		margin-bottom: 120px;
	}	
}


@media (min-width: 992px) {
	#st-notice {
		margin-bottom: 140px;
	}
}



#st-notice .standard-heading {
	margin-top: 50px;

}

#st-notice .standard-heading::after {
	content : "";
	position: absolute;
	height  : 1px;
	width   : 23%;  /* or 100px */
	border-top:1px solid rgba(0,0,0,.1);
}

#st-notice .notices {
	border-left: 1px solid rgba(0,0,0,.1);
}

#st-notice .notices ul {
	list-style: none;
	padding: 0;
	border-top: 1px solid rgba(0,0,0,.1);
	margin-top: 50px;
}



@media all and (max-width: 575px) {
	#st-notice .notices ul {
		margin-bottom: 20px;
		margin-top: 0;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-notice .notices ul {
		margin-bottom: 25px;
	}
}


@media all and (min-width: 768px) {
	#st-notice .notices ul {
		margin-bottom: 30px;
	}
}













#st-notice .notices ul li .post-title {
	display: flex;
	align-items: center;
}


#st-notice .notices ul li {
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,.1);
}



#st-notice .notices ul li .right {
	align-items: center;
}


#st-notice .notices .post-info {
	opacity: .5;
}


@media all and (max-width: 767px) {
	#st-notice .notices .post-info {
		font-size: 12px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-notice .notices .post-info {
		font-size: 13px;
	}
}


@media all and (min-width: 992px) {
	#st-notice .notices .post-info {
		font-size: 14px;
	}
}



@media all and (max-width: 575px) {
	#st-notice .notices ul li {
		padding-left: 0px;
		padding-bottom: 12.5px;
		padding-top: 12.5px;
	}
	#st-notice .standard-heading::after {
		content : "";
		position: absolute;
		top: 30px;
		left: 0;
		height  : 1px;
		width   : 100%;  /* or 100px */
		border-top:1px solid rgba(0,0,0,.1);	
	}

	#st-notice .standard-heading {
		padding-left: 0px;
		padding-bottom: 12.5px;
		padding-top: 12.5px;
	}
	#st-notice .notices {
		border-left: none;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-notice .notices ul li {
		padding-left: 10px;
		padding-bottom: 15px;
		padding-top: 15px;
	}
	#st-notice .standard-heading::after {
		content : "";
		position: absolute;
		top: 30px;
		left: 0;
		height  : 1px;
		width   : 100%;  /* or 100px */
		border-top:1px solid rgba(0,0,0,.1);	
	}

	#st-notice .standard-heading {
		padding-left: 0px;
		padding-bottom: 12.5px;
		padding-top: 12.5px;
	}
	#st-notice .notices {
		border-left: none;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-notice .notices ul li {
		padding-left: 15px;
		padding-bottom: 17.5px;
		padding-top: 17.5px;
	}
}


@media all and (min-width: 992px) {
	#st-notice .notices ul li {
		margin: 0 40px;
		padding-bottom: 20px;
		padding-top: 20px;
	}
	#st-notice .standard-heading h2 {
		margin: 0 40px;
		padding-bottom: 20px;
		padding-top: 20px;
	}
}





#st-notice .notices ul li .post-icon path {
	fill: #EF780B;
	transition: all ease-in-out .3s;
}







#st-notice .notices ul li .post-title, t
#st-notice .notices ul li .post-date {
	padding-top: 2px;
}




#st-notice .notices ul li .post-title {
	color: #242529;
	transition: all ease-in-out .3s;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
	padding-right: 10px;
}

#st-notice .notices ul li .post-title:hover {
	color: #8DB03F;
}



#st-notice .notices ul li .post-download path {
	fill: #5F9FFF;
	transition: all ease-in-out .3s;
}

#st-notice .notices ul li .post-download:hover path {
	fill: #0148FF;
}





@media all and (max-width: 575px) {
	#st-notice .notices ul li .post-icon, 
	#st-notice .notices ul li .post-download {
		margin-right: 10px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#st-notice .notices ul li .post-icon, 
	#st-notice .notices ul li .post-download {
		margin-right: 12.5px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#st-notice .notices ul li .post-icon, 
	#st-notice .notices ul li .post-download {
		margin-right: 15px;
	}
}


@media all and (min-width: 992px) {
	#st-notice .notices ul li .post-icon, 
	#st-notice .notices ul li .post-download {
		margin-right: 17.5px;
	}
}







#st-notice .notices ul li.new .post-icon path {
	fill: #EF780B;
}

#st-notice .notices ul li.new .post-title {
	color: #EF780B;
}






#st-notice .notices .pagination {
	justify-content: center;
}



































