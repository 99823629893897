.form-table td:first-child{word-break:keep-all;padding-left:0}
.form-table.vt-table td:last-child{padding-right:0}
.form-table input,.form-table textarea{width:100%;background:#F9FAFD;border:1px solid rgba(0,0,0,.05);padding:8px 10px;color:#000}
.form-table input{height:34px;font-weight:500}
.form-table .dropdown-select{background:#F9FAFD;border:1px solid rgba(0,0,0,.05);color:#000}
.form-table .dropdown-select .selected{height:34px;padding:8px 10px;font-weight:500}
.form-table .dscr{font-size:calc(1em - 1px);opacity:.8;display:block;margin-top:5px}
.form-table .warning{font-size:calc(1em - 1px);color:red}
#live-broadcast .w-video .video-wrapper iframe{position:absolute;width:100%;height:100%}
.ytp-play-button{fill:#fff;opacity:.85}
.ytp-play-button:hover{cursor:pointer;opacity:1}
.ytp-play-button.closed{opacity:0}
#product-category .ytp-iframe{position:absolute;right:0;bottom:0;min-width:100%;min-height:100%}
#product-content .ytp-wrapper{position:relative;width:100%;height:0;padding-bottom:56.25%}
#product-content .img-wrapper{margin-bottom:10px}
#product-content .video-wrapper{position:relative;width:100%;height:0;padding-bottom:56.25%;margin-bottom:10px}
#product-content .video-wrapper iframe{position:absolute;width:100%;height:100%}
.textCenter{text-align:center!important}
.textLeft{text-align:left!important}
.textRight{text-align:right!important}
.label-table{text-align:center!important;white-space:nowrap}
.w-video{position:relative;transition:all ease-in-out .3s}
.w-video video{width:100%;display:block}
.w-video .thumbnail{cursor:pointer;position:absolute;left:0;top:0;width:100%;height:100%;z-index:1;opacity:1;transition:all ease-in-out .3s}
.w-video .thumbnail .img-wrapper{position:absolute;width:100%;height:100%;top:0;left:0;overflow:hidden;transition:all ease-in-out .3s}
.w-video .thumbnail .img-wrapper:after{position:absolute;content:"";width:100%;height:100%;top:0;left:0;background:#000;opacity:.2;transition:all ease-in-out .3s}
.w-video:hover .thumbnail .img-wrapper:after{opacity:.4}
.w-video .thumbnail .img-wrapper img{position:absolute;top:0;bottom:0;left:0;right:0;width:100%;height:100%;object-fit:cover;object-position:center;transition:all ease-in-out .3s;opacity:1}
.w-video .states .state{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:2;width:auto;height:24%;opacity:.5}
.w-video:hover .states .state{opacity:1}
.w-video.onplay .thumbnail{opacity:0;z-index:-1}
.w-video.onplay .state{width:0;height:0;opacity:0}
.w-video .thumbnail.onpause{opacity:1}
.w-video .thumbnail.onpause .img-wrapper{opacity:0}
.w-checkbox input{width:0;opacity:0}
.w-checkbox label{width:auto;align-items:center}
.w-checkbox label .icon{cursor:pointer;background:#FFF;border:1px solid silver;transition:all ease-in-out .3s}
.w-checkbox.circle label .icon{border-radius:50%}
.w-checkbox.square label .icon{border-radius:5%;border:none;box-shadow:0 5px 10px #EAEBED;transition:all ease-in-out .3s}
.w-checkbox.square label .icon:hover{box-shadow:0 5px 10px rgba(0,0,0,.2)}
.w-checkbox.square label .icon:hover path{fill:#000}
.w-checkbox label .icon path{fill:silver;transition:all ease-in-out .3s}
.w-checkbox.checked label .icon{background:#0F76EF;border:1px solid #0F76EF}
.w-checkbox.checked label .icon path{fill:#FFF}
.w-checkbox label .text{margin-top:auto;margin-bottom:auto}
.w-checkbox label h4{padding-top:2px}
@media all and (max-width: 575px) {
.w-checkbox label .icon{width:22px;height:22px;margin-right:10px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.w-checkbox label .icon{width:24px;height:24px;margin-right:15px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.w-checkbox label .icon{width:26px;height:26px;margin-right:17.5px}
}
@media all and (min-width: 992px) {
.w-checkbox label .icon{width:28px;height:28px;margin-right:20px}
}
.style-1-tabs .tabs-nav li{cursor:pointer;transition:all ease-in-out .3s;padding:0 20px;color:rgba(44,41,45,0.5);border-right:1px solid #EAEBED}
.style-1-tabs .tabs-nav li:hover{color:#ac998a}
.style-1-tabs .tabs-nav li:first-child{padding-left:0}
.style-1-tabs .tabs-nav li:last-child{padding-right:0;border-right:none}
.style-1-tabs .tabs-nav li.active{color:#ac998a}
.style-1-tabs .tabs-nav ul{justify-content:center}
.style-1-tabs .tabs-nav{border-bottom:1px solid #EAEBED}
@media all and (max-width: 575px) {
.style-1-tabs .tabs-nav{padding:10px 0}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.style-1-tabs .tabs-nav{padding:10px 0}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.style-1-tabs .tabs-nav{padding:10px 0}
}
@media all and (min-width: 992px) {
.style-1-tabs .tabs-nav{padding:15px 0;border-bottom:1px solid #EAEBED}
}
.style-1-tabs .tabs-content .tab-content{display:none}
.style-1-tabs .tabs-content .tab-content.active{display:block}
@media all and (max-width: 575px) {
.style-1-tabs .tabs-content{padding-top:30px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.style-1-tabs .tabs-content{padding-top:40px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.style-1-tabs .tabs-content{padding-top:50px}
}
@media all and (min-width: 992px) {
.style-1-tabs .tabs-content{padding-top:60px}
}
.style-1-tabs .tabs-content{padding-bottom:0}
@media all and (max-width: 575px) {
.composer{padding-top:25px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.composer{padding-top:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.composer{padding-top:35px}
}
@media all and (min-width: 992px) {
.composer{padding-top:40px}
}
@media all and (max-width: 991px) {
.composer{width:100%}
.composer-title{padding-left:4%;padding-right:4%}
.composer-body{padding-left:4%;padding-right:4%}
}
.composer-title{font-weight:600}
@media all and (max-width: 575px) {
.composer-title{margin-bottom:15px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.composer-title{margin-bottom:15px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.composer-title{margin-bottom:17.5px}
}
@media all and (min-width: 992px) {
.composer-title{margin-bottom:20px}
}
.composer-body{border-top:3px solid #000}
@media all and (min-width: 992px) {
.composer-full .composer-body{border-bottom:1px solid #EAEBED}
}
.composer-body .dropdown-select .selected{padding:7.5px 10px}
.composer-body input{padding:5px 10px;border:1px solid #EAEBED;width:100%}
.composer-body .dropdown-select .options{padding:7.5px 10px;background:#FFF;box-shadow:0 5px 5px #EAEBED}
.composer-body .info-row{border-bottom:1px solid #EAEBED;align-items:center}
@media all and (max-width: 767px) {
.composer-body .info-row{padding:10px 0}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.composer-body .info-row{padding:12.5px 0}
}
@media all and (min-width: 992px) {
.composer-body .info-row{padding:15px 20px}
}
@media all and (max-width: 991px) {
.composer-full .composer-body .col_left{width:100%;padding:5px 0;margin-bottom:15px}
.composer-full .composer-body .col_right{width:100%}
}
@media all and (min-width: 992px) {
.composer-full .composer-body .col_left{width:360px;padding-top:20px;padding-bottom:20px;border-right:1px solid #EAEBED}
.composer-full .composer-body .col_right{width:calc(100% - 360px);padding-top:35px;padding-bottom:35px;padding-left:30px}
}
.composer-full .composer-body .col_left .info-row:last-child{border-bottom:none}
.composer-full .composer-body .col_left .online-submission .row_1{margin-bottom:7.5px;align-items:center}
.composer-full .composer-body .col_left .online-submission .row_2 a{padding:7.5px 10px}
.composer-full .composer-body .col_left .online-submission .row_2 a:first-child{width:calc(50% - 2.5px);margin-right:5px}
.composer-full .composer-body .col_left .online-submission .row_2 a:last-child{width:calc(50% - 2.5px);opacity:.6}
@media all and (max-width: 575px) {
.composer-compact .title .label{width:50px}
.composer-compact .title .content{width:calc(100% - 50px)}
.composer-compact .editor .label{width:100%;margin-bottom:5px;display:none}
.composer-compact .editor .content{width:100%}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.composer-compact .info-row .label{width:100px}
.composer-compact .info-row .content{width:calc(100% - 100px)}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.composer-compact .info-row .label{width:150px}
.composer-compact .info-row .content{width:calc(100% - 150px)}
}
@media all and (min-width: 992px) {
.composer-compact .info-row .label{width:200px}
.composer-compact .info-row .content{width:calc(100% - 200px)}
}
@media all and (max-width: 991px) {
.composer-compact .composer-body{padding-top:5px}
}
.composer-compact .editor{padding-bottom:0}
.articles-table a{display:block;color:#000;transition:all ease-in-out .3s}
.articles-table tr:hover a{color:#F27360}
.articles-table .online-submission img{width:auto;padding-bottom:2.5px}
.mobile-articles{padding:0}
@media all and (min-width: 992px) {
.mobile-articles{display:none}
}
.mobile-articles li{display:block;border-bottom:3px solid #000}
.mobile-articles li a{display:block}
@media all and (max-width: 575px) {
.mobile-articles li{padding:25px 0}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.mobile-articles li{padding:27.5px 0}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.mobile-articles li{padding:30px 0}
}
.mobile-articles li .article-heading .id{color:#FBA63F}
.mobile-articles li .article-heading{padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid #EAEBED}
.mobile-articles li .article-heading h2{margin-bottom:0}
.mobile-articles li .article-heading .online-submission img{margin-left:5px;width:auto;padding-bottom:2.5px}
.mobile-articles .article .title,.mobile-articles .article .label,.mobile-articles .article .value{color:#000}
.mobile-articles li .article-meta .label{opacity:.5;margin-right:5px}
@media all and (max-width: 767px) {
.payment-method.credit-card .card-type .amount-info{margin-top:5px}
.payment-method.credit-card .card-type .radios-group .radio{margin-bottom:0}
.payment-method.credit-card .card-type .radios-group .radio:not(:last-child){margin-right:20px}
}
@media all and (min-width: 768px) {
.payment-method.credit-card .card-type .amount-info{text-align:right}
}
@media (max-width: 575px) {
#checkout #make-a-payment .payment-method.credit-card{display:none;box-shadow:none}
#checkout #make-a-payment .payment-method.credit-card.active{display:block}
#checkout #make-a-payment .payment-method.credit-card tbody,#checkout #make-a-payment .payment-method.credit-card tbody tr,#checkout #make-a-payment .payment-method.credit-card tbody tr td{display:block;background:#FFF;width:100%;text-align:left}
#checkout #make-a-payment .payment-method.credit-card tbody tr:last-child{border:none}
#checkout #make-a-payment .payment-method.credit-card tbody tr td{text-align:left;border:none;padding-left:0;padding-right:0}
#checkout #make-a-payment .payment-method.credit-card tbody tr td:first-child{padding-top:10px;padding-bottom:0;font-weight:700}
#checkout #make-a-payment .payment-method.credit-card tbody tr td:last-child{padding-top:0;padding-bottom:10px}
}
.payment-method.credit-card .card-type .radios-group .radio{margin-bottom:0}
.payment-method.credit-card .card-type .radios-group .radio:not(:last-child){margin-right:20px}
@media all and (max-width: 767px) {
.payment-method.credit-card .card-type .radios-group{margin-bottom:5px}
}
.payment-method.credit-card .amount input{text-align:right}
@media all and (max-width: 575px) {
.payment-method.credit-card .amount input{margin-left:10px;margin-right:10px}
}
@media all and (min-width: 576px) {
.payment-method.credit-card .amount input{margin-left:5px;margin-right:5px;width:120px}
.payment-method.credit-card .amount .inputs-row{justify-content:end}
}
@media all and (max-width: 767px) {
.payment-method.credit-card .card-number .lb{padding-left:5px;padding-right:5px}
}
@media all and (min-width: 768px) {
.payment-method.credit-card .card-number .lb{padding-left:10px;padding-right:10px}
}
.payment-method.credit-card .validity .lb{padding-left:10px}
.payment-method.credit-card .validity .input-2-digits{width:50px}
.payment-method.credit-card .validity .input-4-digits{width:70px}
@media all and (max-width: 575px) {
.payment-method.credit-card .validity li{margin-bottom:10px}
.payment-method.credit-card .validity .inputs-row .lb{padding-left:5px;padding-right:5px}
}
@media all and (min-width: 576px) {
.payment-method.credit-card .validity li{margin-bottom:10px}
.payment-method.credit-card .validity .inputs-row .lb{padding-left:10px;padding-right:10px}
}
@media all and (max-width: 575px) {
.payment-method.credit-card .installment-period .dropdown-select{min-width:110px}
}
@media all and (min-width: 576px) {
.payment-method.credit-card .installment-period .dropdown-select{min-width:200px}
}
.payment-method.credit-card .installment-period .lb{padding-left:10px}
.payment-method.credit-card .birth-date .lb{padding-left:10px}
.payment-method.credit-card .business-id .lb{padding-left:10px}
.payment-method.credit-card .card-password .lb{padding-left:10px}
@media all and (max-width: 575px) {
.payment-method.credit-card .card-password input{max-width:60px}
}
@media all and (min-width: 576px) {
.payment-method.credit-card .card-password input{max-width:70px}
}
@media all and (max-width: 575px) {
.payment-method.credit-card .card-type-tr{display:none!important}
.payment-method.credit-card .card-type-tr.active{display:block!important}
}
@media all and (min-width: 576px) {
.payment-method.credit-card .card-type-tr{display:none!important}
.payment-method.credit-card .card-type-tr.active{display:table-row!important}
}
.lb{display:flex;align-items:center}
.member-form input.width48{width:40%}
.member-form input.width70{width:60%}
.member-form input.width27{width:35%}
.member-form .inputs-wrapper{align-items:center}
.member-form .hyphen{margin-left:10px;margin-right:10px}
@media all and (max-width: 991px) {
.member-form .form-row{margin-bottom:15px}
}
@media all and (min-width: 992px) {
.member-form .form-row{margin-bottom:20px}
}
.member-form .form-row:last-child{margin-bottom:0}
@media all and (max-width: 575px) {
.member-form .label{margin-bottom:0}
}
@media all and (min-width: 576px) and (max-width: 991px) {
.member-form .label{padding-right:15px}
}
@media all and (min-width: 992px) {
.member-form .label{padding-right:20px}
}
.member-form .dropdown-select .selected{background:#F1F5FB;box-shadow:none}
.member-form .dropdown-select .selected,.member-form input{background:#F1F5FB;border:none}
.member-form input:read-only{color:rgba(43,41,55,.4)}
.member-form .dropdown-select .options{background:#FFF;padding:10px 0;border-top:none;box-shadow:0 5px 10px rgba(0,0,0,.05)}
.member-form .dropdown-select .option{padding:2.5px 15px;transition:all ease-in-out .3s}
.member-form .dropdown-select .option:hover{color:#ac998a}
@media all and (max-width: 419px) {
.member-form .year-select{width:34%;margin-right:2%}
.member-form .month-select{width:31%;margin-right:2%}
.member-form .day-select{width:31%}
.member-form .calendar-select{width:100%;margin-top:10px}
}
@media all and (min-width: 420px) {
.member-form .years-select{width:36%;margin-right:2%}
.member-form .months-select{width:30%;margin-right:2%}
.member-form .days-select{width:30%}
.member-form .calendar-select{width:23%}
}
@media all and (max-width: 420px) {
.member-form .years-select{width:36%;margin-right:2%}
.member-form .months-select{width:30%;margin-right:2%}
.member-form .days-select{width:30%}
.member-form .calendar-select{width:23%}
}
.member-form .verify-btn{display:flex;justify-content:center;align-items:center;width:auto;max-width:100%;box-shadow:0 5px 10px rgba(0,0,0,.05)}
.member-form .input .verify-btn{margin-top:0;margin-left:10px}
@media all and (max-width: 767px) {
.member-form .input .verify-btn{min-width:80px}
}
@media all and (min-width: 768px) {
.member-form .input .verify-btn{min-width:100px}
}
@media all and (max-width: 767px) {
.member-form .extra .verify-btn{margin-top:10px;padding-left:15px;padding-right:15px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.member-form .extra .verify-btn{padding-left:17.5px;padding-right:17.5px}
}
@media all and (min-width: 992px) {
.member-form .extra .verify-btn{padding-left:20px;padding-right:20px}
}
@media all and (max-width: 991px) {
.member-form .verify-btn{padding-top:10px;padding-bottom:10px}
.member-form .dropdown-select .selected,.member-form input{padding:10px 15px}
.member-form .label{padding:10px 0}
}
@media all and (min-width: 992px) {
.member-form .verify-btn{padding-top:10px;padding-bottom:10px}
.member-form .dropdown-select .selected,.member-form input{padding:10px 15px}
.member-form .label{padding:10px 0}
}
.calendar-header{display:flex;justify-content:center;align-items:center;padding-bottom:20px;border-bottom:1px solid #EAEBED;margin-bottom:10px}
.calendar-header .date{font-weight:700;color:#328AFD}
.calendar-header .nav-btn{cursor:pointer}
.calendar-header .nav-btn.prev{margin-right:20px}
.calendar-header .nav-btn.next{margin-left:20px}
.calendar-table{background:#FFF}
.calendar-table th,.calendar-table td{vertical-align:top;width:14.285%;text-align:center}
.calendar-table td:first-child{color:#1F1D29}
.calendar-table td:last-child{color:#1F1D29}
.calendar-table .day.prev,.calendar-table .day.next{opacity:.4}
.calendar-table td .day{display:flex;align-items:center;justify-content:center;width:32px;height:32px;margin-left:auto;margin-right:auto;border-radius:50%;position:relative}
.calendar-table td .day:after{content:"";position:absolute;width:calc(100% + 8px);height:calc(100% + 8px);top:50%;left:50%;transform:translate(-50%,-50%);opacity:.1;border-radius:50%}
.calendar-table td .day.active,.calendar-table td .day.active:after{background:#F5A46C}
.calendar-table td .day.active span{color:#FFF}
.calendar-table td .day.current,.calendar-table td .day.current:after{background:#3580FE}
.calendar-table td .day.current span{color:#FFF}
@media all and (max-width: 767px) {
.calendar thead th span{display:none}
.calendar-body{margin-bottom:20px}
}
@media all and (min-width: 768px) {
.calendar-body{margin-bottom:10px}
}
.calendar .calendar-table thead th{color:#9AAABA}
@media all and (max-width: 767px) {
.calendar-table thead th,.calendar-table td{padding:10px 5px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.calendar-table thead th,.calendar-table td{padding:12.5px 5px}
}
@media all and (min-width: 992px) {
.calendar-table thead th,.calendar-table td{padding:15px 5px}
}
.calendar-table td{position:relative}
.calendar-detail ::-webkit-scrollbar{width:3px}
.calendar-detail table th{border-right:none!important}
@media all and (min-width: 576px) {
.calendar-detail-table{font-size:14px}
}
.calendar-detail-table{text-align:center}
.calendar-detail-table.hr-table thead{background:#FFF}
.calendar-detail-table.hr-table tfoot td{border-bottom:none;color:#F27360}
.calendar-detail-table.hr-table td{border-right:none}
.calendar-detail-table tr.current-month{color:#FFF;background:#F5A46C}
.calendar-section .dot{border-radius:50%;display:block}
.calendar-section .dot.type-1{background:#8256FF}
.calendar-section .dot.type-2{background:#FFDA56}
.calendar-section .dot.type-3{background:#AAE76D}
.calendar-section .dot.type-4{background:#56C2FF}
.calendar-section td .article-dots{position:absolute;bottom:0;left:50%;transform:translateX(-50%);margin:0;padding:0}
.calendar-section td .article-dots .dot{width:4px;height:4px}
.calendar-section td .article-dots .dot:not(:last-child){margin-right:6px}
@media all and (min-width: 576px) {
.calendar-article-types{font-size:14px}
}
.calendar-article-types{margin:0;padding:0}
.calendar-article-types .article-type{margin-bottom:10px;align-items:center}
.calendar-article-types .article-type:not(:last-child){margin-right:30px}
.calendar-article-types .article-type .dot{width:10px;height:10px;margin-right:10px}
.calendar-articles .heading{border-bottom:1px solid #EAEBED;align-items:center;padding-bottom:20px;margin-bottom:0}
@media all and (min-width: 992px) {
.calendar-articles .article-days{overflow:auto;max-height:420px}
}
.calendar-articles .article-days .day:not(:last-child){margin-bottom:20px;border-bottom:1px solid rgba(0,0,0,.05)}
.calendar-articles .article-days .day,.calendar-articles .article-days .day h4{font-size:14px}
.calendar-articles .article-days .day ul{padding:0}
.calendar-articles .article-days .article{display:block}
.calendar-articles .article-days .article a{color:#000;transition:all ease-in-out .3s}
.calendar-articles .article-days .article a:hover{color:#B08374}
@media all and (max-width: 991px) {
.calendar-articles .article-days{padding-top:25px}
}
@media all and (min-width: 992px) {
.calendar-articles .article-days{padding-top:30px}
}
.calendar-articles .day .article .dot{width:10px;height:10px;margin-right:10px;margin-top:6px}
@media (max-width: 991px) {
.hr-chart{font-size:13px}
}
@media (min-width: 992px) {
.hr-chart{font-size:14px}
}
.hr-chart{position:relative;background:#FFF;padding:0 2.5px}
.hr-chart .chart-body{position:relative;min-height:150px}
@media all and (max-width: 991px) {
.hr-chart .chart-body{padding-top:80px}
}
@media all and (min-width: 992px) {
.hr-chart .chart-body{padding-top:60px}
}
.hr-chart ul li{display:block}
.hr-chart .month-row{padding:0;margin:0;text-align:center}
.hr-chart .value-row{align-items:flex-end;margin-bottom:0;z-index:100;position:relative}
.hr-chart .value-row li{position:relative}
.hr-chart .value{position:absolute;left:50%;transform:translateX(-50%)}
.hr-chart .value span{white-space:nowrap;display:inline-block}
/* @media all and (max-width: 991px) {
.hr-chart .value span{transform:rotate(-90deg) translateX(40px)}
} */
@media all and (min-width: 992px) {
.hr-chart .value{top:-30px}
}
.hr-chart .bar{display:block;margin:auto;position:relative}
.hr-chart .bar:after{content:"";display:block;position:absolute;width:100%;height:80%;top:0;left:0;transition:all ease-in-out .3s;background-image:linear-gradient(rgba(255,255,255,.4),rgba(255,255,255,0))}
@media all and (max-width: 575px) {
.hr-chart .bar{width:10px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.hr-chart .bar{width:10px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.hr-chart .bar{width:12.5px}
}
@media all and (min-width: 992px) {
.hr-chart .bar{width:15px}
}
.hr-chart .label-row{padding-top:5px;border-top:1px solid #EAEBED;align-items:flex-start;margin-bottom:0}
.hr-chart .label-row li{display:block;width:100%;text-align:center}
@media all and (max-width: 576px) {
.hr-chart .label-row li span{display:block;width:100%;margin-bottom:-5px}
}
.hr-chart li:nth-child(1) .bar{background:#FAAF3F}
.hr-chart li:nth-child(2) .bar{background:#863FFA}
.hr-chart li:nth-child(3) .bar{background:#FA3F6C}
.hr-chart li:nth-child(4) .bar{background:#3F68FA}
.hr-chart li:nth-child(5) .bar{background:#06D9CC}
.hr-chart li:nth-child(6) .bar{background:#FA3FBA}
.hr-chart li:nth-child(7) .bar{background:#3FC2FA}
.hr-chart li:nth-child(8) .bar{background:#27E5A1}
.hr-chart li:nth-child(9) .bar{background:#A4F240}
.hr-chart li:nth-child(10) .bar{background:#0047FF}
.hr-chart li:nth-child(11) .bar{background:#F2C040}
.hr-chart li:nth-child(12) .bar{background:#FF4975}
.hr-chart .hr-lines{z-index:0;position:absolute;height:100%;top:0}
.hr-chart .hr-lines span{position:absolute;width:100%;height:1px;left:0;background:linear-gradient(to right,#000 50%,rgba(255,255,255,0) 0%);background-position:top,right,bottom,left;background-repeat:repeat-x,repeat-y;background-size:10px 1px;opacity:.1}
.hr-chart .hr-lines span:nth-child(1){top:20%}
.hr-chart .hr-lines span:nth-child(2){top:40%}
.hr-chart .hr-lines span:nth-child(3){top:60%}
.hr-chart .hr-lines span:nth-child(4){top:80%}
@media (max-width:991px){
    .hr-chart{ display: flex; padding-top: 0 !important; border-top:0 !important}
    .hr-chart .label-row{padding:0; border-top:0 !important;}
    .hr-chart .label-row,.hr-chart .value-row{display:flex;flex-direction:column}
    .hr-chart .value-row{flex:1 auto}
    #affiliate-member-analysis .position-chart .hr-chart ul li{width:100%!important;display:flex!important}
    .hr-chart .bar{left:0;height:10px;margin:0;top:6px}
    .hr-chart .value{right:0;left:auto}
}
@media all and (max-width: 575px) {
.section-content .container.results{padding-top:25px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.section-content .container.results{padding-top:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.section-content .container.results{padding-top:35px}
}
.input-2-digits,.input-4-digits,.input-6-digits{letter-spacing:2px}
.tr-option{display:none}
.tr-option.active{display:table-row}
.articles-wrapper.style-3 article{background:#FFF;transition:all ease-in-out .3s}
.articles-wrapper.style-3 h4{color:#000;margin-bottom:10px;transition:all ease-in-out .3s}
.articles-wrapper.style-3 h4:hover{color:#ac998a}
@media all and (max-width: 576px) {
.articles-wrapper.style-3 h4{font-size:14px}
}
.articles-wrapper.style-3 .meta{display:flex;justify-content:flex-start;border-top:1px solid #EAEBED}
.articles-wrapper.style-3 article .meta .info{display:flex;align-items:center;margin-right:20px}
.articles-wrapper.style-3 article .meta .info:last-child{margin-right:0}
.articles-wrapper.style-3 article .meta .info img{margin-right:10px;height:20px;width:auto}
.articles-wrapper.style-3 article .meta .info span{margin-top:1.5px}
@media all and (max-width: 767px) {
.articles-wrapper.style-3 .meta{padding-top:10px}
}
@media all and (min-width: 768px) {
.articles-wrapper.style-3 article{border-left:2px solid #ac998a;box-shadow:0 5px 10px #EAEBED}
.articles-wrapper.style-3 .meta{padding-top:12.5px}
.articles-wrapper.style-3 h4{display:block;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.articles-wrapper.style-3 article{padding:25px 35px}
}
@media all and (min-width: 992px) {
.articles-wrapper.style-3 article{padding:30px 40px}
}
@media all and (max-width: 767px) {
.articles-wrapper.style-3 .column{width:100%}
}
.articles-wrapper.style-2 .meta{display:flex;justify-content:flex-start}
.articles-wrapper.style-2 .meta.between{display:flex;justify-content:space-between}
.articles-wrapper.style-2 .meta .view{margin-right:20px}
.articles-wrapper.style-2 article{box-shadow:none}
.articles-wrapper.style-2 article .info{padding:0;margin-top:20px}
.articles-wrapper.style-2 article .info h4{margin-bottom:10px}
.articles-wrapper.style-2 article .info p{opacity:.5;margin-bottom:10px}
.articles-wrapper.style-2 article .meta div{display:flex;align-items:center}
.articles-wrapper.style-2 article .meta div img{margin-right:10px}
.articles-wrapper.style-2 article .meta div span{margin-top:1.5px}
.articles-wrapper.style-2 article .thumbnail{background:#000;display:block;position:relative}
.articles-wrapper.style-2 article .thumbnail img{transition:all ease-in-out .3s}
@media all and (max-width: 575px) {
.articles-wrapper.style-2 article .thumbnail img{height:170px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.articles-wrapper.style-2 article .thumbnail img{height:250px}
}
@media all and (min-width: 768px) {
.articles-wrapper.style-2 article .thumbnail img{height:270px}
}
.articles-wrapper.style-2 article .thumbnail:hover img:last-child{opacity:.5}
@media all and (max-width: 575px) {
.articles-wrapper.style-2 article .thumbnail .icon{width:20px;height:20px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.articles-wrapper.style-2 article .thumbnail .icon{width:25px;height:25px}
}
@media all and (min-width: 768px) {
.articles-wrapper.style-2 article .thumbnail .icon{width:30px;height:30px}
}
.articles-wrapper.style-2 article .thumbnail .icon{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:100;opacity:.4}
.articles-wrapper.style-2 article .thumbnail:hover .icon{opacity:1}
.articles-wrapper.style-1 article{background:#FFF;box-shadow:0 5px 15px #EAEBED;border-radius:10px}
.articles-wrapper.style-1 article .thumbnail{display:block;padding:50px 20px;background-image:linear-gradient(#6AB3FF,#297AFF);border-top-left-radius:10px;border-top-right-radius:10px}
.articles-wrapper.style-1 article .thumbnail.img{display:block;padding:0;background-image:linear-gradient(#6AB3FF,#297AFF);border-top-left-radius:10px;border-top-right-radius:10px;height:180px;width:100%}
.articles-wrapper.style-1 article .thumbnail.img img{border-top-left-radius:10px;border-top-right-radius:10px}
.articles-wrapper.style-1 article .thumbnail .icon{display:block;margin:auto}
.articles-wrapper.style-1 article .info p{border-bottom:1px solid #EAEBED;padding-bottom:10px}
@media all and (max-width: 575px) {
.articles-wrapper.style-1 article .info .meta span{display:block;width:100%}
}
.articles-wrapper.style-1 article .info .meta{display:flex;justify-content:space-between;align-items:center}
.articles-wrapper.style-1 article .info .meta .icon{height:30px;margin-left:15px}
@media all and (max-width: 575px) {
.articles-wrapper.style-1 article .info{padding:15px 10px}
.articles-wrapper.style-1 article .thumbnail .icon{height:40px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.articles-wrapper.style-1 article .info{padding:15px}
.articles-wrapper.style-1 article .thumbnail .icon{height:50px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.articles-wrapper.style-1 article .info{padding:20px}
.articles-wrapper.style-1 article .thumbnail .icon{height:60px}
}
@media all and (min-width: 992px) {
.articles-wrapper.style-1 article .info{padding:20px}
.articles-wrapper.style-1 article .thumbnail .icon{height:70px}
}
.articles-wrapper{margin-bottom:0}
.articles-wrapper li{display:block}
.articles-wrapper article h4{font-weight:600;margin-bottom:0;line-height:1.4em}
@media all and (max-width: 575px) {
.articles-wrapper article .info h4{font-size:14px}
.articles-wrapper article .info p{font-size:12px;margin-bottom:5px}
.articles-wrapper article .info .meta span{font-size:12px}
}
@media all and (max-width: 575px) {
.articles-wrapper .column{margin-bottom:20px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.articles-wrapper .column{margin-bottom:25px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.articles-wrapper .column{margin-bottom:30px}
}
@media all and (min-width: 992px) {
.articles-wrapper .column{margin-bottom:40px}
}
@media all and (max-width: 575px) {
.articles-wrapper .column{width:calc(50% - 5px);margin-right:10px}
.articles-wrapper .column:nth-child(2n){margin-right:0}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.articles-wrapper .column{width:47.5%;margin-right:5%}
.articles-wrapper .column:nth-child(2n){margin-right:0}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.articles-wrapper.articles-4-cols .column{width:31%;margin-right:3.5%}
.articles-wrapper.articles-4-cols .column:nth-child(3n){margin-right:0}
}
@media all and (min-width: 992px) {
.articles-wrapper.articles-4-cols .column{width:22.75%;margin-right:3%}
.articles-wrapper.articles-4-cols .column:nth-child(4n){margin-right:0}
}
@media all and (min-width: 768px) {
.articles-wrapper.articles-3-cols .column{width:31%;margin-right:3.5%}
.articles-wrapper.articles-3-cols .column:nth-child(3n){margin-right:0}
}
@media all and (min-width: 767px) {
.articles-wrapper.articles-2-cols .column{width:48%;margin-right:4%}
.articles-wrapper.articles-2-cols .column:nth-child(2n){margin-right:0}
}
.office-section input,.office-section textarea{width:100%;background:#F9FAFD;border:1px solid rgba(0,0,0,.05);padding:7px 10px;color:#000}
.office-section .dropdown-select{box-sizing:border-box;background:#F9FAFD;border:1px solid rgba(0,0,0,0.05);border-radius:5px;color:#000}
.office-section .dropdown-select .selected{box-sizing:border-box;background:#F9FAFD;border-radius:5px;color:#000;padding:7px 10px}
.office-section .dropdown-select .options{background:#FFF;padding:7px 10px;border:1px solid rgba(0,0,0,0.05)}
.office-section .dropdown-select .options .option{padding:5px 0}
.office-table p:last-child{margin-bottom:0}
.office-table a{color:#FFF;background:rgba(0,0,0,.6);display:flex;height:100%}
.office-table a span{margin:auto}
@media all and (max-width: 767px) {
.office-table a span{padding:5px 10px}
}
@media all and (min-width: 768px) {
.office-table a span{padding:0 15px}
}
.office-table .dropdown-select .options{padding:5px 0}
.office-table .dropdown-select .options li{padding:2.5px 10px}
.inputs-row{margin:0;padding:5px 0;align-items:center}
.inputs-row .input{display:flex;align-items:center}
.draggable-wrapper{position:relative;width:100%;overflow-x:auto;overflow-y:hidden;white-space:nowrap;transition:all ease-in-out .3s;will-change:transform;user-select:none}
.draggable-wrapper.active{background:rgba(255,255,255,0.3);cursor:grabbing;cursor:-webkit-grabbing}
.table-wrapper table{min-width:100%;width:auto;white-space:nowrap}
.draggable-wrapper::-webkit-scrollbar,.table-wrapper::-webkit-scrollbar{height:2px}
.no-thead{margin-top:5px;position:relative}
.no-thead:after{content:"";position:absolute;top:-5px;left:0;width:100%;height:5px;background:#555}
.hr-mobile-table tbody tr:last-child{border-bottom:none}
@media all and (max-width: 575px) {
.hr-mobile-table tbody:last-child{margin-bottom:0}
}
@media all and (min-width: 576px) and (max-width: 991px) {
.hr-mobile-table tbody:last-child,.hr-mobile-table tbody:nth-last-child(2):not(:nth-child(2n)){margin-bottom:0}
}
.hr-mobile-table .only-tbody{width:100%;margin-right:0}
@media all and (max-width: 639px) {
.hr-mobile-table tbody:first-child:last-child{width:100%;margin-right:0;margin-bottom:30px}
}
@media all and (min-width: 640px) and (max-width: 991px) {
.hr-mobile-table tbody{display:block;float:left;width:48%;margin-right:4%}
.hr-mobile-table tbody:nth-child(2n){margin-right:0}
.hr-mobile-table tbody:first-child:last-child{width:100%;margin-right:0;margin-bottom:30px}
}
@media all and (max-width: 575px) {
.hr-mobile-table tbody:not(:last-child){margin-bottom:20px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.hr-mobile-table tbody:not(:last-child){margin-bottom:25px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.hr-mobile-table tbody:not(:last-child){margin-bottom:30px}
}
.hr-mobile-table tbody{display:block;background:#FFF;border-top:5px solid #FF866B;font-size:14px}
.hr-mobile-table tbody tr:first-child{font-weight:600}
.hr-mobile-table td:first-child,.hr-mobile-table th:first-child{width:35%;text-align:center;border-right:1px solid rgba(0,0,0,.05)}
.hr-mobile-table td:last-child,.hr-mobile-table th:last-child{width:65%}
.hr-mobile-table tr{display:flex}
.hr-mobile-table tr:first-child td{padding-top:10px}
.hr-mobile-table tr:not(:last-child){border-bottom:1px solid #EAEBED}
.hr-mobile-table td{display:block}
.hr-mobile-table tr:last-child td{padding-bottom:10px}
.hr-mobile-table .tfhead td{width:100%;background:#555;color:#FFF;border-bottom:1px solid #FFF;font-weight:700}
.hr-mobile-table td{text-align:center}
@media all and (max-width: 991px) {
.hr-table{display:none}
}
@media all and (min-width: 576px) {
.hr-table{font-size:14px}
}
.hr-table{background:#FFF}
.hr-table thead{background:#f2f2f2;color:#1F1D29;border-bottom:1px solid #EAEBED}
.hr-table th{background-color: #f2f2f2;}
/* .hr-table thead th:last-child{border-right:none} */
.hr-table td{border-bottom:1px solid #EAEBED}
/* .hr-table td:last-child{border-right:none} */
.normal-content .hr-mobile-table td{line-height:1.2em}
.normal-content .hr-mobile-table ul{padding:0;margin:0}
.normal-content .hr-mobile-table li{display:block;padding:7.5px 0;border-bottom:1px solid #EAEBED}
.normal-content .hr-mobile-table li:first-child{padding-top:0}
.normal-content .hr-mobile-table li:last-child{padding-bottom:0;border-bottom:none}
@media all and (max-width: 991px) {
.normal-content .hr-mobile-table{display:table}
.normal-content .hr-mobile-table tbody{display:table-row-group}
.normal-content .hr-mobile-table tr{display:table-row}
.normal-content .hr-mobile-table td{display:table-cell;background:#FFF;border:1px solid #EAEBED!important}
}
.vt-table{position:relative;background:#FFF;border-top:1px solid #EAEBED;line-height:1.25em;word-break:break-all}
@media all and (min-width: 576px) {
.vt-table{font-size:14px}
}
.vt-table tbody tr{border-bottom:1px solid #EAEBED}
.vt-table td:first-child{border-right:1px solid #EAEBED;font-weight:500;background:#FFF}
.vt-table.alternated td:first-child{background:#FFF}
@media all and (max-width: 767px) {
.vt-table td:first-child{width:35%}
.vt-table td:last-child{width:65%}
}
@media all and (min-width: 768px) {
.vt-table td:first-child{width:25%}
}
@media all and (min-width: 992px) {
.hr-mobile-table{display:none}
}
@media all and (max-width: 575px) {
.hr-table th{padding:9.5px 10px}
.hr-table td{padding:7px 10px}
.hr-mobile-table td,.vt-table td{padding:7px 10px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.hr-table th{padding:10px}
.hr-table td{padding:7px 10px}
.hr-mobile-table td,.vt-table td{padding:7px 12.5px}
}
@media all and (min-width: 768px) {
.hr-table th{padding:10.5px 10px}
.hr-table td{padding:7.5px 10px}
.hr-mobile-table td,.vt-table td{padding:8.5px 15px}
}
.check-option{display:flex}
.check-option input{width:16px;height:16px;margin-top:2.5px}
@media all and (max-width: 767px) {
.check-option input{margin-right:7.5px}
}
@media all and (min-width: 768px) {
.check-option input{margin-right:10px}
}
.radios-group{margin:0;padding-left:0}
.radios-group .radio{display:flex;cursor:pointer;align-items:center}
.radios-group .radio input,.radios-group .radio label{cursor:pointer}
.radios-group .radio{display:flex;align-items:center}
@media all and (max-width: 767px) {
.radios-group .radio:last-child{margin-bottom:0}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.radios-group .radio:not(:last-child){margin-right:12.5px}
}
@media all and (min-width: 992px) {
.radios-group .radio:not(:last-child){margin-right:15px}
}
.radios-group .radio input{width:18px;height:18px}
.radios-group .radio label{padding-left:5px}
.checkbox input{width:16px;height:16px}
.checkbox{margin-bottom:5px}
.checkbox:last-child{margin-bottom:0}
.checkbox label{padding-left:10px;line-height:1.25em}
@media all and (min-width: 576px) {
.office-section .office-form{font-size:14px}
}
.office-section .office-heading h2{font-weight:500;margin-bottom:5px}
@media all and (max-width: 575px) {
.office-section .office-heading{padding-left:4%;padding-right:4%}
}
@media all and (max-width: 767px) {
.office-section .office-heading{margin-bottom:10px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.office-section .office-heading{margin-bottom:12.5px}
}
@media all and (min-width: 992px) {
.office-section .office-heading{margin-bottom:15px}
}
.office-section .office-form{border-top:1px solid #EAEBED}
@media all and (max-width: 575px) {
.office-section .office-form .form-body{padding:20px 4%;padding-bottom:0}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.office-section .office-form .form-body{padding-top:20px}
}
@media (min-width: 768px) {
.office-section .office-form .form-body{padding-top:25px}
}
.office-section .office-form .form-header{border-bottom:1px solid #EAEBED}
@media all and (max-width: 575px) {
.office-section .office-form .form-body{padding:20px 4%;padding-bottom:0}
.office-section .office-form .form-header{padding-left:4%;padding-right:4%}
}
@media all and (max-width: 767px) {
.office-section .office-form .form-header .radios-group{padding:15px 0}
}
@media (min-width: 768px) {
.office-section .office-form .form-header .radios-group{padding:20px 0}
}
.office-section .guide{opacity:.5;display:flex;align-items:center;margin-left:10px}
.office-section .unit{display:flex;align-items:center;margin-left:10px}
.office-section .unit:not(:last-child){margin-right:10px}
@media all and (min-width: 576px) {
.office-section .office-form .info{align-items:center}
}
@media all and (max-width: 991px) {
.office-section .office-form .info{margin-bottom:15px}
}
@media all and (min-width: 992px) {
.office-section .office-form .info{margin-bottom:20px}
}
@media all and (max-width: 575px) {
.office-section .office-form .info .label{width:100%;margin-bottom:7.5px}
}
.dropdown-select ::-webkit-scrollbar{width:4px}
.dropdown-select ::-webkit-scrollbar-thumb{background:#555}
.dropdown-select{position:relative}
.dropdown-select .selected{cursor:pointer;width:100%}
.dropdown-select .selected span{display:block;margin-right:5px;margin-top:auto;margin-bottom:auto}
.options{max-height:200px;overflow-x:hidden;display:none;position:absolute;left:0;right:0;z-index:9999;padding:0;top:100%}
.options .option{display:block;cursor:pointer;transition:all ease-in-out .3s}
@media all and (max-width: 991px) {
.shipping-information .result-block{margin-bottom:25px}
}
@media all and (min-width: 992px) {
.result-block{margin-bottom:30px}
}
.block-heading{display:flex;align-items:center}
.block-heading h2{margin-bottom:0;padding-right:20px;font-weight:600}
@media all and (max-width: 575px) {
.block-heading{margin-bottom:12.5px}
.block-heading a{padding:5px 20px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.block-heading{margin-bottom:15px}
.block-heading a{padding:5px 20px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.block-heading{margin-bottom:17.5px}
.block-heading a{padding:7.5px 20px}
}
@media all and (min-width: 992px) {
.block-heading{margin-bottom:20px}
.block-heading a{padding:7.5px 20px}
}
.pagination{justify-content:space-between}
.pagination .per-page{background:#FFF}
.pagination .per-page .label{display:flex}
.pagination .per-page .label span{display:block;margin:auto;font-size:13px}
.pagination .per-page .dropdown-select .options{background:#FFF}
.pagination .per-page .dropdown-select .option{color:#2A2E46}
.pagination .per-page .dropdown-select .option.active,.pagination .per-page .dropdown-select .option:hover{color:#F88125}
.pagination .paging{justify-content:center}
.mobile-pagination{margin:15px 0}
@media all and (max-width: 991px) {
.mobile-pagination{justify-content:space-between}
}
@media all and (min-width: 992px) {
.mobile-pagination{display:none}
}
.desktop-pagination{justify-content:space-between}
@media all and (max-width: 991px) {
.desktop-pagination .per-page,.desktop-pagination .total{display:none!important}
.desktop-pagination .paging{width:100%}
.desktop-pagination{margin-top:10px}
}
@media all and (min-width: 992px) {
.pagination .per-page,.pagination .total{width:160px}
.desktop-pagination{margin-top:30px}
}
.pagination .paging .numbers .number{display:flex;cursor:pointer;transition:all ease-in-out .3s;margin-top:auto;margin-bottom:auto;color:rgba(0,0,0,1)}
.pagination .paging .nav-btn{cursor:pointer;border:1px solid #EAEBED}
@media all and (max-width: 360px) {
.pagination .paging .nav-btn svg{width:26px;height:26px}
}
@media all and (min-width: 361px) and (max-width: 575px) {
.pagination .paging .nav-btn svg{width:30px;height:30px}
}
@media all and (max-width: 575px) {
.pagination .paging .numbers .number{margin-right:5px}
.pagination .paging .numbers{margin-left:5px;margin-right:5px}
}
@media all and (min-width: 576px) {
.pagination .paging .numbers .number{margin-right:10px}
.pagination .paging .numbers{margin-left:10px;margin-right:10px}
}
@media all and (max-width: 575px) {
.pagination .paging .numbers .number{width:28px;height:28px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.pagination .paging .numbers .number{width:30px;height:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.pagination .paging .numbers .number{width:32px;height:32px}
}
@media all and (min-width: 992px) {
.pagination .paging .numbers .number{width:34px;height:34px}
}
.pagination .paging .numbers .number:hover{color:#242529;background:#FFF;box-shadow:0 5px 10px #EAEBED}
.pagination .paging .numbers .number.active{color:#fff;background:#F3A41F}
.pagination .paging .numbers .number:last-child{margin-right:0}
.pagination .paging .numbers .number span{display:block;margin:auto}
.pagination .paging .btns-left .nav-btn,.pagination .paging .btns-right .nav-btn{cursor:pointer}
.pagination .paging .nav-btn path{transition:all ease-in-out .3s}
.pagination .paging .nav-btn:hover path{fill:#242529}
.pagination .total{display:flex;justify-content:flex-end;align-items:center}
.pagination .total span{margin-top:auto;margin-bottom:auto}
.pagination .total .label{margin-right:10px}
.pagination .total .value{color:#000}
.pagination .per-page .dropdown-select{width:70px;background:transparent}
/* .pagination .per-page .dropdown-select .selected{padding:0 10px} */
.pagination .per-page .dropdown-select .options{padding:10px;border:1px solid #EAEBED}
.post-foot{display:flex;justify-content:space-between;margin-top:20px}
.post-foot a{display:flex;color:#000;align-items:center;cursor:pointer}
.post-foot a:hover{opacity:.8}
.post-foot .carret{display:flex;align-items:center}
.post-foot .carret.left{margin-right:10px;transform:rotate(-180deg)}
.post-foot .carret.right{margin-left:10px}
.draggable-element{overflow:hidden}
.draggable-items-wrapper{position:relative;width:100%;overflow-x:hidden;overflow-y:hidden;white-space:nowrap;transition:all ease-in-out .3s;will-change:transform;user-select:none}
.draggable-items-wrapper.active{background:rgba(255,0,0,0.3);cursor:grabbing;cursor:-webkit-grabbing}
.block-loading{display:none}
.block-loading.is-loading{display:block}
.is-loading .overlay{left:0;top:0;width:100%;height:100%;position:fixed;background:rgba(0,0,0,0.5);z-index:1000000000000}
.is-loading .overlay__inner{left:0;top:0;width:100%;height:100%;position:absolute}
.is-loading .overlay__content{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}
.spinner{width:75px;height:75px;display:inline-block;border-width:2px;border-color:rgba(255,255,255,0.05);border-top-color:#fff;animation:spin 1s infinite linear;border-radius:100%;border-style:solid}
@keyframes spin {
100%{transform:rotate(360deg)}
}
.w-video .thumbnail.onpause{opacity:1}
.w-video .thumbnail.onpause .img-wrapper{opacity:0}
.w-btn{position:relative;transition:all ease-in-out .3s;text-align:center;display:inline-flex;justify-content:center;align-items:center;border:1px solid rgba(0,0,0,0);cursor:pointer;line-height:1.2em}
.w-btn span{display:flex;align-self:center;transition:all ease-in-out .3s}
.w-btn.has-icon span{padding-right:5px}
.w-btn .icon{display:flex;align-items:center;transition:all ease-in-out .3s}
.w-btn svg,.w-btn svg path{transition:all ease-in-out .3s}
.prime-btn{color:#fff;background:#ff9841;box-shadow:0 5px 10px #EAEBED;padding:0 16px;}
.prime-btn:hover{color:#FFF;background:#ff9841;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.prime-btn:active{color:#FFF;background:#ec8000;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.prime-btn:hover span{color:#FFF}
.blue-btn{color:#fff;background:#90ca56;box-shadow:0 5px 10px #EAEBED}
.blue-btn:hover{color:#FFF;background:#6db821;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.blue-btn:active{color:#FFF;background:#6db821;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.blue-btn:hover span{color:#FFF}
.green-btn{color:#fff;background:#0CBA86;box-shadow:0 5px 10px #EAEBED}
.green-btn:hover{color:#FFF;background:#00A372;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.green-btn:active{color:#FFF;background:#00855D;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.green-btn:hover span{color:#FFF}
.outline-btn{color:#2A2E46;background:#fff;border:1px solid #C0C1C8}
.outline-btn:hover{color:#ec8000;border:1px solid #ec8000}
.outline-btn:hover span{color:#ec8000}
.ghost-btn{background-color:#687484;padding:10px 15px}
.ghost-btn.plain-text{color:#2A2E46;background-color:#EAEBED;display:block}
.ghost-btn:hover{color:#4B61E7}
.ghost-btn:hover span{color:#ec8000}
.dark-btn{color:#fff;background:#8A91A6;box-shadow:0 5px 10px #EAEBED}
.dark-btn:hover{color:#FFF;background:#737C98;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.dark-btn:active{color:#FFF;background:#657090;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.dark-btn:hover span{color:#FFF}
.rounded-btn{color:#2A2E46;background:#fff;border:1px solid #2A2E46;border-radius:100px;padding-left:20px;padding-right:20px;min-width:110px}
@media all and (max-width: 991px) {
.rounded-btn{height:42px}
}
@media all and (min-width: 992px) {
.rounded-btn{height:44px}
}
.rounded-btn span{position:relative;z-index:2;text-transform:uppercase;font-weight:500;font-size:14px}
.rounded-btn:hover span{transition:all ease-in-out .3s;color:#000}
.rounded-btn div{content:"";position:absolute;z-index:1;top:50%;left:50%;transform:translate(-50%,-50%);border-radius:100px;width:0;height:0;background:#FAEB66;transition:all ease-in-out .3s}
.rounded-btn:hover div{width:calc(100% - 8px);height:calc(100% - 8px)}
.simple-btn{display:flex;flex-wrap:wrap;align-items:center}
.simple-btn span{flex:0 0 auto;margin-right:10px;color:#2A2E46;transition:all ease-in-out .3s;font-weight:600}
.simple-btn:hover span{color:#4B61E7}
.simple-btn svg{flex:0 0 auto}
.simple-btn svg path{transition:all ease-in-out .3s}
.simple-btn:hover svg path{stroke:#4B61E7}
.light-btn{border:1px solid #6b0000;color:#2A2E46}
.light-btn:hover{box-shadow:0 5px 10px #000;color:#2A2E46}
.semi-light-btn{border:1px solid #6b0000;color:#2A2E46}
.semi-light-btn:hover{box-shadow:0 5px 10px #000;color:#2A2E46}
@media all and (max-width: 991px) {
.md-btn{height:42px}
.sm-btn{font-size:14px;height:36px}
.xs-btn{font-size:13px;height:24px}
}
@media all and (min-width: 992px) {
.md-btn{height:44px}
.sm-btn{font-size:14px;height:38px}
.xs-btn{font-size:13px;height:26px}
}
.group-btns{justify-content:center}
@media all and (max-width: 767px) {
.group-btns{margin-top:30px}
.group-btns a:not(:last-child){margin-right:10px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.group-btns{margin-top:30px}
.group-btns a:not(:last-child){margin-right:12.5px}
}
@media all and (min-width: 992px) {
.group-btns{margin-top:30px}
.group-btns a:not(:last-child){margin-right:15px}
}
.w-youtube-video{position:relative;background:#000;overflow:hidden;display:block}
.w-youtube-video:after{content:"";display:block;padding-top:56.25%}
.w-youtube-video img{position:absolute;top:0;bottom:0;left:0;right:0;width:100%;height:100%;object-fit:cover;object-position:center;transition:all ease-in-out .5s}
.item .static{opacity:0;transition:all ease-in-out .5s}
.item:hover .static{opacity:1}
.w-youtube-video{position:relative}
.w-youtube-video .ytp-button{position:absolute;background:rgba(0,0,0,.4);z-index:999;transition:all ease-in-out .5s;top:50%;left:50%;transform:translate(-50%,-50%)}
@media all and (max-width: 991px) {
.w-youtube-video .ytp-button:hover{opacity:1}
}
@media all and (min-width: 992px) {
.w-youtube-video .ytp-button{opacity:.5}
.w-youtube-video .ytp-button:hover{opacity:1}
}
.w-youtube-video .ytp-button.closed{left:-50%;opacity:0}
@media all and (max-width: 575px) {
.w-youtube-video .ytp-button{width:28px;height:28px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.w-youtube-video .ytp-button{width:32px;height:32px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.w-youtube-video .ytp-button{width:36px;height:36px}
}
@media all and (min-width: 992px) {
.w-youtube-video .ytp-button{width:40px;height:40px}
}
.w-youtube-video .video-wrapper{position:absolute;width:100%;height:100%;top:0;left:0;background:#000;display:flex;align-items:center;justify-content:center;transition:all ease-in-out .3s}
.w-youtube-video .video-wrapper iframe{width:100%;height:auto}
.work-help-article .post-meta .info .label{opacity:.5;margin-right:5px}
.work-help-article .post-meta .info:not(:last-child){margin-right:20px}
@media all and (max-width: 767px) {
.work-help-article .post-header{margin-bottom:15px}
.work-help-article .post-body .vt-table td:last-child{padding:15px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.work-help-article .post-header{margin-bottom:17.5px}
.work-help-article .post-body .vt-table td:last-child{padding:17.5px}
}
@media all and (min-width: 992px) {
.work-help-article .post-header{margin-bottom:20px}
.work-help-article .post-body .vt-table td:last-child{padding:20px}
}
.empty-table{position:relative}
.red-line{color:red;text-decoration:line-through}
table thead th{font-weight:500}
.empty-data{position:relative}
.empty-data span{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}
.h100{height:100px}
.h150{height:150px}
.h200{height:200px}
.h250{height:250px}
.h300{height:300px}