


.w-alert-container {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.5);
	z-index: 99999999999999999999999;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 10vh;
	transition: all ease-in-out .3s;
}

.w-alert-container.show {
	width: 100vw;
	height: 100vh;
}









@media all and (max-width: 575px) {
	.w-alert {
		padding: 50px 30px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.w-alert {
		padding: 55px 35px;
	}
}


@media all and (min-width: 768px) {
	.w-alert {
		padding: 60px;
	}
}







.w-alert {
	background: #FFF;
	width: 92%;
	max-width: 540px;
	border-radius: 10px;
	box-shadow: 0px 15px 45px rgba(0,0,0,.1);
	display: none;
	transition: all ease-in-out .3s;
}

.w-alert.show {
	display: block;
}





.w-alert {
	text-align: center;
	color: #2A2E46;
}


.w-alert .icon {
	margin-bottom: 40px;
}


.w-alert .icon img {
	width: auto;
	display: block;
	margin-left: auto;
	margin-right: auto;
}


.w-alert .dscr {
	opacity: .5;
}


.w-alert .w-btn {
	padding: 10px 20px;
	border-radius: 5px;
	min-width: 120px;
	color: #FFF;
}



@media all and (max-width: 575px) {
	.w-alert .w-btn:not(:last-child) {
		margin-right: 10px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.w-alert .w-btn:not(:last-child) {
		margin-right: 12.5px;
	}
}


@media all and (min-width: 768px) {
	.w-alert .w-btn:not(:last-child) {
		margin-right: 15px;
	}
	
}
















.w-alert .cancel-btn {
	background: #FF4F44;
}

.w-alert .cancel-btn:hover {
	background: #EA362B;
	box-shadow: 0px 10px 15px rgba(0,0,0,.1);
}

.w-alert .cancel-btn:active {
	background: #D40A0A;
}




.w-alert .ok-btn {
	background: #EF780B;
}

.w-alert .ok-btn:hover {
	background: #EF780B;
	box-shadow: 0px 10px 15px rgba(0,0,0,.1);
}

.w-alert .ok-btn:active {
	background: #88B84C;
	box-shadow: 0px 10px 15px rgba(0,0,0,.1);
}







.w-alert .icon .border {
	transform-origin: center center;
	animation: rotateBorder .5s linear;
	-webkit-animation: rotateBorder .5s linear;
	-moz-animation: rotateBorder .5s linear;
}








.w-alert.confirm .icon .tip {
	transform-origin: center center;
	animation: rotateConfirmTip .5s linear;
	-webkit-animation: rotateConfirmTip .5s linear;
	-moz-animation: rotateConfirmTip .5s linear;
}


.w-alert.confirm .icon .dot {
	transform-origin: center center;
	animation: rotateConfirmDot .5s linear;
	-webkit-animation: rotateConfirmDot .5s linear;
	-moz-animation: rotateConfirmDot .5s linear;
}








.w-alert.cancelled .icon .mark {
	transform-origin: center center;
	animation: rotateY360 .5s linear;
	-webkit-animation: rotateY360 .5s linear;
	-moz-animation: rotateY360 .5s linear;
}






.w-alert.successful .icon .mark {
	transform-origin: center center;
	animation: rotateY360 .5s linear;
	-webkit-animation: rotateY360 .5s linear;
	-moz-animation: rotateY360 .5s linear;
}




.w-alert.warning .icon .mark {
	transform-origin: center center;
	animation: rotateY360 .5s linear;
	-webkit-animation: rotateY360 .5s linear;
	-moz-animation: rotateY360 .5s linear;
}

















@-webkit-keyframes rotateConfirmTip {
	0% { 
		transform: rotate(90deg); 
		opacity: 0;
	}
	100% { 
		transform: rotate(0deg); 
		opacity: 1;
	}
}

@keyframes rotateConfirmTip {
	0% { 
		transform: rotate(90deg); 
		opacity: 0;
	}
	100% { 
		transform: rotate(0deg); 
		opacity: 1;
	}
}






@-webkit-keyframes rotateConfirmDot {
	0% { 
		transform: translateX(-20px); 
		opacity: 0;
	}
	100% { 
		transform: translateX(0px); 
		opacity: 10;
	}
}

@keyframes rotateConfirmDot {
	0% { 
		transform: translateX(-20px); 
		opacity: 0;
	}
	100% { 
		transform: translateX(0px); 
		opacity: 10;
	}
}










@-webkit-keyframes rotateY360 {
	0% { 
		transform: rotateY(360deg);
		opacity: 0;
	}
	100% { 
		transform: rotateY(0);
		opacity: 1;
	}
}

@keyframes rotateY360 {
	0% { 
		transform: rotateY(360deg);
		opacity: 0;
	}
	100% { 
		transform: rotateY(0);
		opacity: 1;
	}
}









@-webkit-keyframes rotateBorder {
	0% { 
		transform: rotate(360deg); 
		opacity: 0;
	}
	100% { 
		transform: rotate(0deg); 
		opacity: .5;
	}
}

@keyframes rotateBorder {
	0% { 
		transform: rotate(360deg); 
		opacity: 0;
	}
	100% { 
		transform: rotate(0deg); 
		opacity: .5;
	}
}














