.office-search{font-size:13px;background:#F3F4FB}
.office-search input{background:#FFF;border:1px solid #EAEBED;width:100%;height:40px}
@media all and (max-width: 575px) {
.office-search .selected,.office-search input{padding:0 10px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.office-search .selected,.office-search input{padding:0 11px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.office-search .selected,.office-search input{padding:0 12px}
}
@media all and (min-width: 992px) {
.office-search .selected,.office-search input{padding:0 13px}
}
.office-search .selected{height:40px}
.composer-body .office-search .selected span{padding:0}
.office-search .display-stage .info{padding:7.5px 5px;background:#fff;border:1px solid rgba(0,0,0,.1);border-radius:5px}
.office-search .display-stage{min-width:95px}
.office-search .display-stage .info{align-items:center}
.office-search .display-stage .info .value{padding:0 12.5px}
.office-search .display-stage .up-btn,.office-search .display-stage .down-btn{margin-top:auto;margin-bottom:auto;display:flex;cursor:pointer}
.office-search .display-stage .up-btn svg,.office-search .display-stage .down-btn svg{margin-left:auto;margin-right:auto}
.office-search .display-stage .up-btn{margin-right:5px}
.office-search .date-selects .info-heading .btn-hide{margin-right:0;margin-left:auto}
.office-search .date-selects .info-heading .btn-hide span{color:#7D7893}
.office-search .date-selects .info-heading{display:flex;align-items:center}
@media all and (max-width: 991px) {
.office-search .date-selects .info-heading{margin-bottom:10px}
.office-search .date-selects .info-heading .label{color:#000}
}
@media all and (min-width: 992px) {
.office-search .date-selects .info-heading .btn-hide{display:none}
}
.office-search .date-select .years-select,.office-search .date-select .months-select,.office-search .date-select .days-select{display:flex;align-items:center;position:relative}
.office-search .date-select .years-select:after,.office-search .date-select .months-select:after{content:"";position:absolute;top:50%;transform:translateY(-50%);right:0;background:rgba(0,0,0,.1);width:1px;height:75%}
.office-search .date-select .dropdown-select{width:100%}
@media all and (max-width: 575px) {
.office-search .date-time .lb{display:none}
.office-search .date-time .date-select.date-from{margin-bottom:10px}
}
@media all and (max-width: 991px) {
.office-search .date-select .years-select{width:40%}
.office-search .date-select .months-select{width:30%}
.office-search .date-select .days-select{width:30%}
}
@media all and (min-width: 992px) {
.office-search .date-select .years-select{width:38%}
.office-search .date-select .months-select{width:31%}
.office-search .date-select .days-select{width:31%}
}
.office-search .label{display:flex;align-items:center;font-weight:500}
@media all and (max-width: 767px) {
.office-search .field{align-items:center}
.office-search .label{font-size:12px;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:1;/*height:1.5em;*/display:-webkit-box;-webkit-box-orient:vertical}
}
/* @media all and (min-width: 768px) and (max-width: 991px) {
.office-search .label{width:110px}
} */
.office-search .close-btn a{height:100%;display:flex;align-items:center;padding-left:20px;padding-right:20px}
.office-search .lb{display:flex;align-items:center;margin-left:5px;margin-right:5px}
.office-search .lb-text{display:flex;align-items:center;color:rgba(43,41,55,.5)}
.office-search .dropdown-select .selected{border:1px solid rgba(0,0,0,.1);background:#fff}
.office-search .name-browsing-btn{background:#687484;width:40px;height:40px;transition:all ease-in-out .3s;box-shadow:0 5px 10px rgba(0,0,0,.1)}
.office-search .name-browsing-btn:hover{background:#000;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.office-search .submit-btn,.office-search .top-line-btn{position:relative;cursor:pointer;text-align:center;border:none;height:100%;padding-top:10px;padding-bottom:10px}
.office-search .submit-btn{background:#687484;transition:all ease-in-out .3s;box-shadow:0 5px 15px rgba(0,0,0,0.1)}
.office-search .submit-btn:hover{background:#000;box-shadow:0 10px 15px rgba(0,0,0,.2)}
.office-search .submit-btn{padding-left:20px;padding-right:20px}
.office-search .submit-wrapper{margin-right:0;margin-left:auto}
.office-search .submit-btn:after,.office-search .top-line-btn:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;transition:all ease-in-out .3s;background-image:linear-gradient(rgba(255,255,255,.3),rgba(255,255,255,0));opacity:0}
.office-search .submit-btn:hover:after,.office-search .top-line-btn:hover:after{opacity:1}
.office-search .submit-btn,.office-search .top-line-btn{color:#FFF;line-height:1.3em}
.office-search .submit-btn path,.office-search .top-line-btn path{fill:#fff}
.office-search .standard-name .name-selected{display:flex;align-items:center;padding:0 15px;overflow:hidden}
.office-search .standard-name .name-selected span{display:block;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}
.office-search .name-browsing-btn{padding:0 10px}
.office-search .name-browsing-btn path{fill:#fff}
.empty-tbody{position:relative;border:1px solid rgba(0,0,0,.1);margin-bottom:0!important}
.empty-tbody td{background:#FFF!important;padding:5px 10px!important;border:none!important}
.empty-tbody .empty-warning{display:block;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center}
.empty-warning{text-align:center}
@media all and (min-width: 992px) {
.office-result{padding-top:40px;padding-bottom:100px}
}
.office-result .hr-table{border-top:1px solid #EAEBED}
.office-result .hr-table td,.office-result .hr-table th{padding-left:20px;text-align:left}
.office-result .hr-mobile-table tbody{font-size:13px;line-height:1.25em;border-left:1px solid rgba(0,0,0,.075);border-right:1px solid rgba(0,0,0,.075);border-bottom:1px solid rgba(0,0,0,.075)}
.office-result .hr-mobile-table tbody td:first-child{width:45%;text-align:left;word-break:break-all}
.office-result .hr-mobile-table tbody td:last-child{width:55%;text-align:left;word-break:break-all}
.office-result tfoot tr{background:#EFF7FF;border-bottom:1px solid #EAEBED}
.office-result tfoot tr th,.office-result tfoot tr td{font-weight:600}
.office-block h2{font-weight:600;display:flex;align-items:center}
@media all and (max-width: 767px) {
.office-block .heading{margin-bottom:14px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.office-block .heading{margin-bottom:16px}
}
@media all and (min-width: 992px) {
.office-block .heading{margin-bottom:18px}
}