/* input,
button,
.verify-btn {
	border-radius: 5px;
} */

.memnberTab .pagination .per-page .dropdown-select {
	background: transparent;
	width: 70px;
}

.memnberTab .pagination .per-page .label {
	padding-right: 0;
}

.memnberTab .dropdown-select .selected span {
	padding: 0;
}




/*------ Order Management ------
		1. Order Search
---------------------------------*/












/*------ Order Management ------
		2. Sub-order Search
---------------------------------*/


@media all and (min-width: 992px) {
	#sub-order-search .date-input, 
    #sub-order-search .date-search {
		height: 40px;
	}

}







/*------ Order Management ------
		3. Order Product Search
---------------------------------*/



















/*------ Order Management ------
		4. Order Detail Inquiry
---------------------------------*/













/*------ Order Management ------
		5. Order Calendar
---------------------------------*/






#order-calendar .section-content .hr-table {
	text-align: center;
}

#order-calendar .memnberTab {
	margin-top: 0;
}











/*----------- Monthly Order Count -------------*/


@media all and (max-width: 991px) {
	#monthly-order-count .searching-result .searching-form .label {
		width: 75px;
	}

	#monthly-order-count .searching-result .standard-member-name,
	#monthly-order-count .searching-result .order-type,
	#monthly-order-count .searching-result .inquiry-classification,
	#monthly-order-count .searching-result .display-item {
		margin-bottom: 12.5px;
	}
}



@media all and (max-width: 991px) {
	#monthly-order-count .searching-result .advanced-search-items .submit-btn-wrapper {
		width: 100%;
	}
}








@media all and (min-width: 576px) and (max-width: 991px) {
	#monthly-order-count .standard-member-name,
	#monthly-order-count .searching-result .order-type,
	#monthly-order-count .searching-result .inquiry-classification,
	#monthly-order-count .searching-result .display-item {
		width: calc(50% - 5px);
	}

	#monthly-order-count .standard-member-name,
	#monthly-order-count .searching-result .inquiry-classification {
		margin-right: 10px;
	}

	#monthly-order-count .searching-form .form-row.date-time .date-from {
		width: 100%;
	}
}




@media all and (max-width: 575px) {
	#monthly-order-count .searching-result .standard-member-name,
	#monthly-order-count .searching-result .order-type,
	#monthly-order-count .searching-result .inquiry-classification,
	#monthly-order-count .searching-result .display-item {
		margin-bottom: 10px;
	}

	#monthly-order-count .searching-form .form-row.date-time .date-selects {
		margin-bottom: 0;
	}
}



@media all and (min-width: 992px) and (max-width: 1199px) {
	#monthly-order-count .searching-result .standard-member-name,
	#monthly-order-count .searching-result .order-type,
	#monthly-order-count .searching-result .inquiry-classification,
	#monthly-order-count .searching-result .display-item {
		margin-right: 20px;
	}
}


@media all and (min-width: 1200px) {
	#monthly-order-count .searching-result .standard-member-name,
	#monthly-order-count .searching-result .order-type,
	#monthly-order-count .searching-result .inquiry-classification,
	#monthly-order-count .searching-result .display-item {
		margin-right: 25px;
	}
}





#monthly-order-count .searching-form .date-select .years-select:after {
	width: 0;
}



@media all and (min-width: 992px) {
	#monthly-order-count .searching-form .date-select .years-select {
		width: 100%;
	}
}


#monthly-order-count .searching-result .searching-form .form-row.date-time .date-selects {
	border-right: none;
	padding-right: 0;
}


@media all and (min-width: 992px) {
	#monthly-order-count .searching-result .searching-form .submit-btn {
		min-width: 100px !important;
	}

	#monthly-order-count .searching-result .searching-form .form-row .dropdown-select {
		border: 1px solid rgba(0, 0, 0, .1);
		border-radius: 5px;
	}

	#monthly-order-count .searching-form .date-from {
		border: none;
	}
}


@media all and (min-width: 575px) and (max-width: 767px) {
	#monthly-order-count .searching-result .searching-form .form-row.date-time .date-selects {
		margin-bottom: 15px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#monthly-order-count .searching-result .searching-form .form-row.date-time .date-selects {
		width: 100%;
		margin-bottom: 15px;
	}
}


@media all and (max-width: 991px) {
	#monthly-order-count .searching-result .searching-form .date-selects .years-select {
		width: 100%;
	}
}




@media all and (min-width: 992px) and (max-width: 1199px) {

	/*---- Row 1 ----*/

	#monthly-order-count .searching-result .searching-form .date-selects,
	#monthly-order-count .searching-result .searching-form .standard-member-name,
	#monthly-order-count .searching-result .searching-form .order-type {
		width: calc(33.33333% - 13.333333px);
		margin-bottom: 15px;
	}

	#monthly-order-count .searching-result .searching-form .date-selects,
	#monthly-order-count .searching-result .searching-form .standard-member-name {
		margin-right: 20px;
	}

	#monthly-order-count .searching-result .searching-form .order-type {
		margin-right: 0px;
	}


	/*---- Row 2 ----*/

	#monthly-order-count .searching-result .searching-form .inquiry-classification,
	#monthly-order-count .searching-result .searching-form .display-item,
	#monthly-order-count .searching-result .searching-form .submit-btn-wrapper {
		width: calc(33.33333% - 13.333333px);
	}

	#monthly-order-count .searching-result .searching-form .inquiry-classification,
	#monthly-order-count .searching-result .searching-form .display-item {
		margin-right: 20px;
	}

}








/*----------- Monthly Order Count -------------*/


#phase-balance-inquiry .section-content .container.orderTab {
	margin-top: 0;
}

#phase-balance-inquiry .heightTab {
	height: 40px;
}
