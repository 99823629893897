/**/





.featured-slider.on .slider-content {
	overflow-y: hidden;
	overflow-x: hidden;	
}




.featured-slider.on {
	position: relative;
}



@media all and (min-width: 992px) {
	.featured-slider.on.decay-slider {
		display: flex;
	}
}






.featured-slider.on .container {
	width: 100%;
	max-width: none;
}



.featured-slider.on .slider-wrapper {
	padding: 0;
}




.featured-slider.on .slider-wrapper {
	display: flex;
	flex-wrap: nowrap;
	position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    will-change: transform;
    user-select: none;
    cursor: pointer;
	transition: all ease-in-out .5s;
	visibility: hidden;
}


.featured-slider.on .slider-wrapper .slide {
	transition: all ease-in-out .3s;	
}

.featured-slider.on .slider-wrapper .slide a {
	display: block;
}


.featured-slider.on .slider-wrapper .slide.active {
	pointer-events: auto;
	opacity: 1;
	cursor: pointer;
}


.featured-slider.on .slider-wrapper .slide:last-child {
	margin-right: 0;
}
























/*------ Slider Nav ------*/





.featured-slider.on .nav {
	position: absolute;
	cursor: pointer;
	transition: all ease-in-out .3s;
	background-color: white;
	opacity: 0.4;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 50%; 
	transform: translateY(-50%);
}


@media all and (max-width: 575px) {
	.featured-slider:not(.products-slider).on .nav {
		width: 28px;
		height: 28px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.featured-slider:not(.products-slider).on .nav {
		width: 32px;
		height: 32px;
	}

}


@media all and (min-width: 768px) and (max-width: 991px) {
	.featured-slider:not(.products-slider).on .nav {
		width: 36px;
		height: 36px;
	}
}


@media all and (max-width: 991px) {
	.featured-slider:not(.products-slider).on .nav {
		background: rgba(0,0,0,.1);
	}

}

@media all and (min-width: 992px) {
	.featured-slider:not(.products-slider).on .nav {
		width: 44px;
		height: 44px;
	}
	.featured-slider:not(.products-slider).on .nav:hover {
		background: rgba(0,0,0,.5);
	}

}



.featured-slider:not(.products-slider).on .nav svg {
	fill: none;
	width: 60%;
	height: 60%;
}

.featured-slider:not(.products-slider).on .nav-banner svg {
	fill: none;
	width: 60%;
	height: 60%;
}


.featured-slider:not(.products-slider).on .nav svg path {
	box-shadow: 0px 5px 5px rgba(0,0,0,.1);
	transition: all ease-in-out .3s;
	stroke: white;
}

.featured-slider:not(.products-slider).on .nav:hover svg path {
	box-shadow: 0px 5px 5px rgba(0,0,0,.5);
	
}


.featured-slider:not(.products-slider).on .nav.prev {
	left: 4%;
}

.featured-slider:not(.products-slider).on .nav.next {
	right: 4%;
}


/* slide banner */
.featured-slider.on .slide-footer {
	width: 100%;
    height: 50px;
    bottom: 0.01%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
	/* opacity: 0.5; */
	/* background-color: #2A2E46; */
}

.featured-slider.on .flex-item {
    margin: 20px 20px 20px 20px;
}
/* slide banner end */

/* number banner */
.featured-slider.on .slide-footer .slider-number-banner {
	color: #FFF;
}

.featured-slider.on .slider-number .number-wrapper-banner span {
	letter-spacing: 3px;
}

.featured-slider.on .slider-number .total-number-banner {
	opacity: .5;
}
/* number banner end */

/* nav banner */
.featured-slider.on .nav-banner {
	cursor: pointer;
	background-color: transparent;
	transition: all ease-in-out .3s;
}

/* .featured-slider.on .nav-banner {
	position: absolute;
	cursor: pointer;
	background-color: transparent;
	transition: all ease-in-out .3s;
	opacity: 0.8;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 3.2%;
}

@media all and (max-width: 575px) {
	.featured-slider:not(.products-slider).on .nav-banner {
		width: 28px;
		height: 28px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.featured-slider:not(.products-slider).on .nav-banner {
		width: 32px;
		height: 32px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.featured-slider:not(.products-slider).on .nav-banner {
		width: 36px;
		height: 36px;
	}
}


@media all and (max-width: 991px) {
	.featured-slider:not(.products-slider).on .nav-banner {
		background: rgba(0,0,0,.1);
	}
}

@media all and (min-width: 992px) {
	.featured-slider:not(.products-slider).on .nav-banner {
		width: 44px;
		height: 44px;
	}
	.featured-slider:not(.products-slider).on .nav-banner:hover {
		background: rgba(0,0,0,.5);
	}
}


.featured-slider:not(.products-slider).on .nav-banner svg path {
	box-shadow: 0px 5px 5px rgba(0,0,0,.1);
	transition: all ease-in-out .3s;
}

.featured-slider:not(.products-slider).on .nav-banner:hover svg path {
	box-shadow: 0px 5px 5px rgba(0,0,0,.5);
	
}


.featured-slider:not(.products-slider).on .nav-banner.prev {
	left: 41%;
}

.featured-slider:not(.products-slider).on .nav-banner.next {
	right: 45%;
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.featured-slider:not(.products-slider).on .nav-banner.prev {
		left: 35%;
	}
	
	.featured-slider:not(.products-slider).on .nav-banner.next {
		right: 45%;
	}
} */

/* nav banner end */













/*------ Slider Dots ------*/

.featured-slider:not(.products-slider).on .slider-dots {
	display: flex;
	position: absolute;
	margin: auto;
	width: 92%;
	max-width: 1200px;
	left: 50%;
	transform: translateX(-50%);
}



.featured-slider:not(.products-slider).on .slider-dots div {
	cursor: pointer;
	margin-right: 10px;
}

.featured-slider:not(.products-slider).on .slider-dots div:last-child {
	margin-right: 0;	
}

.featured-slider:not(.products-slider).on .slider-dots div span {
	display: block;	
	background: #FFF;	
	width: 10px; 
	height: 10px;
	border-radius: 20px;
	transition: all ease-in-out .3s;
	box-shadow: 0px 2.5px 5px rgba(0,0,0,.1);
	/* margin: 5px; */
}

.featured-slider.on .slider-dots div:hover span {
	box-shadow: 0px 2.5px 10px rgba(0,0,0,.2);
}


.featured-slider:not(.products-slider).on .slider-dots div.active span {
	width: 40px;	
}







@media all and (max-width: 575px) {
	.featured-slider.on .slider-dots {
		bottom: 15px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.featured-slider.on .slider-dots {
		bottom: 15px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.featured-slider.on .slider-dots {
		bottom: 20px;
	}	
}


	
@media all and (min-width: 992px) {
	.featured-slider.on .slider-dots {
		bottom: 30px;
	}	
}














@media all and (max-width: 575px) {
	.featured-slider.on .slider-number {
		bottom: 15px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.featured-slider.on .slider-number {
		bottom: 15px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.featured-slider.on .slider-number {
		bottom: 20px;
	}	
}


	
@media all and (min-width: 992px) {
	.featured-slider.on .slider-number {
		bottom: 30px;
	}	
}






.featured-slider.on .slider-number {
	position: absolute;
	width: 100%;
	/* max-width: 1200px; */
	color: #FFF;
	/* left: 50%; */
	transform: translateX(-50%);
}


.featured-slider.on .slider-number .number-wrapper {
	position: absolute;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
}


.featured-slider.on .slider-number .number-wrapper span {
	letter-spacing: 3px;
}



.featured-slider.on .slider-number .current-number {
	padding-right: 10px;
	/* border-right: 1px solid rgba(255,255,255,.5); */
}


.featured-slider.on .slider-number .total-number {
	padding-left: 10px;
	opacity: .5;
}







.products-slider.on .nav {
    width: 30px;
	height: 50px;
} 


@media all and (max-width: 991px) {
	.products-slider.on .nav {
        /* top: 30%; Oanh change 26Oct2023 */
        top: 40%;
    }    
}

@media all and (min-width: 992px) {
	.products-slider.on .nav {
        top: 40%;
    } 
}

@media all and (max-width: 492px) {
	.products-slider.on .nav {
        top: 40%;
    }    
}




.products-slider.on .nav svg path {
	stroke: #2A2E46; 
}


.products-slider.on .nav.prev {
    /* left: 0; */
	left: 20px;
}

.products-slider.on .nav.next {
    /* right: 0; */
	right: 20px;
}


.products-slider ::-webkit-scrollbar {
	height: 3px;
	background: rgba(0,0,0,.0);
    border-bottom: 1px solid rgba(0,0,0,.1);
}



.products-slider ::-webkit-scrollbar-thumb {
	background: #2A2E46; 
}




.products-slider.on .slider-dots {
    width: 100%;
}

.products-slider.on .slider-dots .dots-wrapper {
    align-items: flex-end;
}


.products-slider.on .slider-dots .dot {
    flex: 1 0 0%;
    height: 1px;
    background: rgba(0,0,0,.1);
}

.products-slider.on .slider-dots .dot.active {
    height: 3px;
    background: #2A2E46;
}

























































