
/* icon fontawesome css 4.7.0 loaded from CDN */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
/* google font */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

/* core bootstrap css 5.3.1 loaded from CDN */
/*@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css");*/

@import url("assets/css/animate.css");
@import url("assets/css/jquery.wowChart.css");
@import url("assets/css/datepicker.css");

@import url("assets/css/home/index.css");
@import url("assets/css/home/product-introduction.css");
@import url("assets/css/home/help-center.css");

@import url("assets/css/header.css");
@import url("assets/css/footer.css");
@import url("assets/css/style.css");
@import url("assets/css/featured-slider.css");
@import url("assets/css/layout.css");
@import url("assets/css/popup.css");
@import url("assets/css/featured-slider.css");
@import url("assets/css/article.css");
@import url("assets/css/modules-office.css");
@import url("assets/css/modules.css");
@import url("assets/css/w-alert.css");

@import url("assets/css/shopping-mall/single-product.css");
@import url("assets/css/shopping-mall/product-category.css");
@import url("assets/css/shopping-mall/cart.css");
@import url("assets/css/shopping-mall/checkout.css");

@import url("assets/css/my-office/order-management.css");
@import url("assets/css/my-office/benefit-management.css");
@import url("assets/css/my-office/schedule.css");
@import url("assets/css/my-office/member-management.css");
@import url("assets/css/my-office/user-profile.css");
@import url("assets/css/my-office/omnitrition.css");
@import url("assets/css/my-office/autoship.css");
@import url("assets/css/my-office/login.css");
@import url("assets/css/style-additional.css");


:root {
    --light-blue: #F3F4FB;
    --quarry-blue:#454F5F;
    --deep-sky-blue:#0F76EF;
    --sea-going:#687484; /*Primary color*/
    --maritime-blue: #2E3144;
    --benihi-red: #F05438;
    --mild-heart:#F27360;
    --ornery-tangerine: #EC8000;
    /* --absolute-apricot:#ff9841; */
    /* --supernatural-saffron: #EF780B; */
    --russian-black: #242529;
    --lace-cap: #EAEBED;
    --wash-me: #F9FAFD;
    --white: #fff;
    --black: #000;
    --sesame-green:#00A372;
    --bleached-silk :#f2f2f2
}
.hr-table thead td {background-color: var(--bleached-silk);}
.hr-table .col-empty{ height: 45px;}
.table td .empty-warning, .hr-table td .empty-warning{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #FFF;padding: 10px;}
.form-control{border-radius: 0;font-size: 13px;}
.btn{--bs-btn-border-radius:0 !important}
.btn.btn-orange {background-color: var(--ornery-tangerine);color: var(--white)}
.btn.btn-quarry-blue {background-color: var(--quarry-blue);color: var(--white);box-shadow: 0 5px 10px rgba(0,0,0,.1)}
  /**/
  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }  
  .form-check-input:checked[type="checkbox"]{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  }
/* Edit css styled custom*/
.products-wrapper{padding:0}
.selected.col-auto.row, .inputs-wrapper.row { margin-left:0 ;margin-right:0 ;}
.dropdown-select.col{padding-left:0;padding-right:0}
#product-content .tabs-nav ul li:after{background-color:#90CA56!important}
.input-group .outline-btn {border-color: #eaebed;}
.quantity-input.row > *, .row.quantity-wrapper > *{ padding-left: 0; padding-right: 0;}
.dropdown-select{display: flex;flex-wrap: wrap;}
.col-form-label{ font-weight: 600;}
.input-group > .dropdown-select{ width: 1%; border-radius: 0;flex: 1 1 auto;min-width: 0;}
.options{min-width: 100px;}
input[type="checkbox"], input[type="radio"]{width: 1em; height: 1em; padding: 0 !important;}
.office-search .form-check-inline { height: 40px;padding-top: 10px;}
.position-absolute-xxs{
  position: absolute
}
@media (min-width:505px){
  .position-absolute-non-xxs{
    position: absolute !important;
  }
  .position-absolute-xxs{
    position:static !important
  }
}
@media (min-width:320px) and (max-width:520px){
  .block-btn-xxs{display: grid !important;}
}
@media (max-width: 1200px) {
    .container {max-width: 100%;}
  }
@media (min-width:992px) and (max-width:1199px){
    .office-search .offset-xmd-1{margin-left: 11%;}
    .office-search .col-xmd-11{width: 89%;}
}
@media (min-width:1200px){
    .btn-xl-h88{ max-height: 88px;}
}
@media (min-width:992px){
    .row-lg-12 [class*="col-lg-12"]{
        flex: 1 0 auto;
        width: 50%;
    }
    .row-lg-12 [class*="col-lg-12 mb-2"]{
        margin-bottom: 0 !important;
    }
    .heading-office {
      align-items: stretch;
    }
    .heading-office h2{
      position: relative;
      padding-bottom: 10px;
      min-height: 58px;
      align-items: center;
      flex-direction: row;
    }
    .heading-office h2::after{
      position: absolute;
    }
}
.nowrap{white-space: nowrap;}
.input-group-updown .btn-group{background: #FFF;border: 1px solid #EAEBED; }
.input-group-updown .btn-group .btn, .input-group-updown .btn-group{border-radius: 0 !important;}
/* fix mobile paging */
.mobile-pagination .row.form{ align-items: center;}
.hr_scrll-menu{height:45px;flex-wrap: nowrap; white-space: nowrap; overflow: hidden; background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));background-position: left center, right center, left center, right center;background-repeat: no-repeat;background-color: white;
background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;/* Opera doesn't support this in the shorthand */background-attachment: local, local, scroll, scroll;}
.hr_scrll-menu:hover{overflow-x: auto;scroll-behavior: smooth;scrollbar-width: thin;}
.hr_scrll-menu::-webkit-scrollbar{ height: 8px;}
.hr_scrll-menu > li:first-child{ padding-left: 0;}
.rwd_dropdown-menu{position:relative;min-width:250px;background:#fff;display:flex;justify-content:space-between;position:relative;min-width:250px;background:#fff;min-width:250px}
/* RWD dropdown submenu */
.rwd_dropdown-menu .visible-links li:first-child a{padding-left:0}
.rwd_dropdown-menu a{display:block;padding:11px 10px;text-decoration:none}
.rwd_dropdown-menu button{padding:0 15px;border:0;outline:none;background-color:#f57c00;color:#fff;cursor:pointer;position:relative}
.rwd_dropdown-menu button:hover{background-color:#ef6c00}
.rwd_dropdown-menu button::after{content:attr(count);position:absolute;width:30px;height:30px;left:-20px;top:8px;text-align:center;background-color:#ef6c00;color:#fff;font-size:14px;line-height:24px;border-radius:50%;border:3px solid #fff;font-weight:700}
.rwd_dropdown-menu button:hover::after{transform:scale(1.075)}
.rwd_dropdown-menu .hamburger{position:relative;width:32px;height:4px;background:#fff;margin:auto}
.rwd_dropdown-menu .hamburger:before,.rwd_dropdown-menu .hamburger:after{content:'';position:absolute;left:0;width:32px;height:4px;background:#fff}
.rwd_dropdown-menu .hamburger:before{top:-8px}
.rwd_dropdown-menu .hamburger:after{bottom:-8px}
.rwd_dropdown-menu .visible-links{display:inline-table}
.rwd_dropdown-menu .visible-links li{display:table-cell!important}
.rwd_dropdown-menu .hidden-links{width:200px;position:absolute;right:0;top:100%;background:#fff;z-index:1;border:1px solid rgba(0,0,0,.075);box-shadow:0 5px 15px rgba(0,0,0,.1);padding:0}
.rwd_dropdown-menu .hidden-links li{display:block;text-align:left}
.rwd_dropdown-menu .hidden{visibility:hidden}
.rwd_dropdown-menu .hidden-links > li a{background:#fff}
/* RWD sticky header, footer scrolling vertical with fixed height and sticky the first column*/
.table-scroll{position:relative;width:100%;z-index:1;margin:auto;overflow:auto;}
.table-scroll table{/*width:100%;min-width:1280px;*/margin:auto;border-collapse:separate;border-spacing:0}
.table-scroll thead th:first-child{position:-webkit-sticky;position:sticky;top:0; left:0; z-index:2;background: #f2f2f2;}
.table-scroll tbody th:first-child, .table-scroll tbody td:first-child{position:-webkit-sticky;position:sticky;left:0;z-index:2;background: #fff;}
.table-scroll thead td:first-child{background: #fff;}
.table-scroll tfoot td:first-child{position:-webkit-sticky;position:sticky;bottom:0; left:0}
.table-scroll tfoot,.table-scroll tfoot th,.table-scroll tfoot td{position:-webkit-sticky;position:sticky;bottom:0;z-index:4; background:#EFF7FF}
.table-scroll thead th:first-child,tfoot td:first-child{z-index:5}
/* RWD with split by row with attribute on td*/
@media only screen and (max-width: 760px),(min-device-width: 768px) and (max-device-width: 1024px) {
    .table-splitedRow table,.table-splitedRow thead,.table-splitedRow tbody,.table-splitedRow th,.table-splitedRow td,.table-splitedRow tr{display:block}
    .table-splitedRow thead tr{position:absolute;top:-9999px;left:-9999px}
    .table-splitedRow tr{border:1px solid #ccc}
    .table-splitedRow td{border:none;border-bottom:1px solid #eee;position:relative;padding-left:50% !important; min-height: 35px;}
    .table-splitedRow td:before{content: attr(data-label);position:absolute;top:6px;left:6px;width:45%;padding-right:10px;white-space:nowrap}
}
/* popup */
.center-popup{top: 50%;transform: translate(-50%, -50%);position: absolute;left: 50%;}







