/**





/*---- Payment Form ----*/




#checkout .payment .form .col_1 input, 
#checkout .payment .form .col_2 input, 
#checkout .payment .form .selected {
    background: #F9FAFD;
    border: 1px solid #EAEBED;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

#checkout .shipping .form .selected {
    background: #F9FAFD;
    border: 1px solid #EAEBED;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

#checkout .payment .form .dscr {
    display: block;
    font-size: calc(1em - 1px);
    margin-top: 5px;
}

#checkout .payment .form .hyphen {
    margin-right: 5px;
    margin-left: 5px;
}




#checkout .payment .form .label {
    font-weight: 600;
}

@media all and (min-width: 576px) {
	#checkout .payment .form .label {
        height: 40px;
        display: flex;
        align-items: center;
    }
}



#checkout .payment .form .row_1 {
    padding: 15px 0;
    border-bottom: 1px solid #EAEBED;
    margin-bottom: 20px;
}





@media all and (max-width: 575px) {
	#checkout .payment .form .field-payment-method .radio {
        width: 100%;
    }
    #checkout .payment .form .field-payment-method .radio:not(:last-child) {
        margin-bottom: 5px;
    }

}


@media all and (min-width: 576px) and (max-width: 767px) {
	#checkout .payment .form .field-payment-method .radio:not(:last-child) {
        margin-right: 30px;
    }
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#checkout .payment .form .field-payment-method .radio:not(:last-child) {
        margin-right: 35px;
    }
}


@media all and (min-width: 992px) {
	#checkout .payment .form .field-payment-method .radio:not(:last-child) {
        margin-right: 40px;
    }
}




@media all and (min-width: 992px) {
	#checkout .payment .form .col_1 {
        width: 50%;
        padding-right: 2%;
    }
    #checkout .payment .form .col_2 {
        width: 50%;
        padding-left: 2%;
    }
}





@media all and (max-width: 575px) {
	#checkout .payment .form .col_1 .field, 
    #checkout .payment .form .col_2 .field:not(:last-child) {
        margin-bottom: 15px;
    }
}


@media all and (min-width: 576px) and (max-width: 991px) {
	#checkout .payment .form .col_1 .field, 
    #checkout .payment .form .col_2 .field:not(:last-child) {
        margin-bottom: 15px;
    }
}


@media all and (min-width: 992px) {
	#checkout .payment .form .col_1 .field:not(:last-child), 
    #checkout .payment .form .col_2 .field:not(:last-child) {
        margin-bottom: 10px;
    }
}



@media all and (max-width: 575px) {
	#checkout .payment .form .label {
        width: 100%;
        margin-bottom: 5px;
    }
}

@media all and (min-width: 576px) and (max-width: 991px) {
	#checkout .payment .form .label {
        width: 145px;
    }
}

@media all and (min-width: 992px) {
    #checkout .payment .form .label {
        height: 40px;
        display: flex;
        align-items: center;
        width: 145px;
    }
}


