/**/




#change-personal-information .form .selected {
    background: #F9FAFD;
    border: 1px solid #EAEBED;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    align-items: center;
}

#change-personal-information .form .field-birthdate .years-select, 
#change-personal-information .form .field-birthdate .months-select,
#change-personal-information .form .field-birthdate .days-select {
    flex: 1 0 0%;
}


#change-personal-information .form .field-birthdate .years-select, 
#change-personal-information .form .field-birthdate .months-select {
    margin-right: 5px;
}






#change-bank-account .form .selected {
    background: #F9FAFD;
    border: 1px solid #EAEBED;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    align-items: center;
}
