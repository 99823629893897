
/* Back to Top  Oanh add 30Oct2023*/
#back-to-top{
    background: rgba(240, 84, 56, .2);
    transition: all ease-in-out .3s;
    position: fixed;
    z-index: 1000; 
    bottom: 10%;
    cursor: pointer;
}
#back-to-top{
    right: 20px;
    width: 40px;
    height: 40px;
}
#back-to-top svg{
    display: block;
}
#back-to-top:hover{
    background: rgba(240, 84, 56, 1);
}
#back-to-top .shape{
    fill: #fff;
}
@media(min-width: 992px){
    #back-to-top{
        right: 20px;
        width: 40px;
        height: 40px;
    }
}