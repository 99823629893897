/**/




/*------- Autoship Payment Status -------*/

@media all and (max-width: 575px) {
	#autoship-payment-status .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
		margin-bottom: 5px;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns a {
		flex: 1 0 0%;
	}
}





@media all and (min-width: 576px) and (max-width: 991px) {
	#autoship-payment-status .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns {
		justify-content: flex-start;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns a {
		margin-bottom: 0;
		padding: 9px 15px;
	}
}


@media all and (max-width: 991px) {
	#autoship-payment-status .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}
















/*------- Autoship Registration Details -------*/

@media all and (max-width: 767px) {
	 #auto-ship-registration .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	 #auto-ship-registration .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
		margin-bottom: 5px;
	}
	 #auto-ship-registration .searching-result .searching-form .mobile-time-btns a {
		flex: 1 0 0%;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	 #auto-ship-registration .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	 #auto-ship-registration .searching-result .searching-form .mobile-time-btns {
		justify-content: flex-start;
	}
	 #auto-ship-registration .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}
	 #auto-ship-registration .searching-result .searching-form .mobile-time-btns a {
		margin-bottom: 0;
		padding: 9px 15px;
	}
}


@media all and (max-width: 991px) {
	 #auto-ship-registration .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}





















/*------- Benefit Details Inquiry -------*/

@media all and (max-width: 991px) {
	#benefit-details-inquiry .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	#benefit-details-inquiry .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
		margin-bottom: 5px;
	}
	#benefit-details-inquiry .searching-result .searching-form .mobile-time-btns a {
		flex: 1 0 0%;
	}
}

@media all and (max-width: 991px) {
	#benefit-details-inquiry .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}























/*------- Benefit Accounting Inquiry -------*/

@media all and (max-width: 767px) {
	#benefit-accounting-inquiry .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	#benefit-accounting-inquiry .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
		margin-bottom: 5px;
	}
	#benefit-accounting-inquiry .searching-result .searching-form .mobile-time-btns a {
		flex: 1 0 0%;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#benefit-accounting-inquiry .searching-result .searching-form .date-select {
		margin-top: 0;
	}
	#benefit-accounting-inquiry .searching-result .searching-form .mobile-time-btns {
		justify-content: flex-start;
	}
	#benefit-accounting-inquiry .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}
	#benefit-accounting-inquiry .searching-result .searching-form .mobile-time-btns a {
		margin-bottom: 0;
		padding: 9px 15px;
	}
}


@media all and (max-width: 991px) {
	#benefit-accounting-inquiry .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}









/*------- Benefit Sponsorship Details -------*/

@media all and (max-width: 575px) {	
	#autoship-payment-status .searching-result .searching-form .mobile-date-time-filter a {
		flex: 1 0 0%;
		padding: 8px 0px;
		margin-bottom: 10px;
	}	
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}	
}


@media all and (min-width: 576px) and (max-width: 991px) {
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns {
		justify-content: flex-start;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}
	#autoship-payment-status .searching-result .searching-form .mobile-time-btns a {
		margin-bottom: 0;
		padding: 11px 20px;
	}
}


@media all and (max-width: 991px) {
	#autoship-payment-status .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}










/*------- Benefit Remittance Details -------*/

@media all and (max-width: 575px) {	
	#benefit-remittance-details .searching-result .searching-form .mobile-date-time-filter a {
		flex: 1 0 0%;
		padding: 8px 0px;
		margin-bottom: 10px;
	}	
	#benefit-remittance-details .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}	
}


@media all and (min-width: 576px) and (max-width: 991px) {
	#benefit-remittance-details .searching-result .searching-form .mobile-time-btns {
		justify-content: flex-start;
	}
	#benefit-remittance-details .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}
	#benefit-remittance-details .searching-result .searching-form .mobile-time-btns a {
		margin-bottom: 0;
		padding: 11px 20px;
	}
}


@media all and (max-width: 991px) {
	#benefit-remittance-details .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}







/*------- Witholding Tax -------*/


@media all and (max-width: 575px) {	
	#witholding-tax .searching-result .searching-form .mobile-date-time-filter a {
		flex: 1 0 0%;
		padding: 8px 0px;
		margin-bottom: 10px;
	}	
	#witholding-tax .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}	
}

@media all and (min-width: 576px) and (max-width: 991px) {
	#witholding-tax .searching-result .searching-form .mobile-time-btns {
		justify-content: flex-start;
	}
	#witholding-tax .searching-result .searching-form .mobile-time-btns {
		margin-top: 0;
	}
	#witholding-tax .searching-result .searching-form .mobile-time-btns a {
		margin-bottom: 0;
		padding: 11px 20px;
	}
}



@media all and (max-width: 991px) {
	#witholding-tax .searching-result .searching-form .date-selects .info-heading .btn-hide {
		pointer-events: none;
		opacity: .5;
	}
}


























@media all and (max-width: 575px) {
	
}


@media all and (min-width: 576px) and (max-width: 767px) {
	
}


@media all and (min-width: 768px) and (max-width: 991px) {
	
}


@media all and (min-width: 992px) {	
	#box-lineage .chart-module {
		overflow: hidden;
		position: relative;
		border-radius: 20px;
	}
	#box-lineage .chart-container {
		border-radius: 20px;		
	}
}


#box-lineage .chart-module {
	padding: 0;
	position: relative;
}


#box-lineage .zoom-btn {
	cursor: pointer;
	z-index: 5;
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 10px;
}

#box-lineage .zoom-btn svg {
	width: 20px;
	height: 20px;
}

#box-lineage .zoom-btn svg path {
	transition: all ease-in-out .3s;
}

#box-lineage .zoom-btn:hover svg path {
	fill: #5B67D6;
}







#box-lineage .node .info {
	padding: 15px 0;
}

#box-lineage .node .info .name {
	font-weight: 700;
	line-height: 1em;
}

#box-lineage .name-box-find {
	cursor: pointer;
}


/* #box-lineage .node.level-0 .info .name {
	color: #0046A3;
}

#box-lineage .node.level-1 .info .name {
	color: #FB873A;
}

#box-lineage .node.level-2 .info .name {
	color: #7EC145;
}

#box-lineage .node.level-x .info .name {
	color: #F85A2D;
} */

/* #box-lineage .node.deleted-user .info .name {
	color: #B1B1B1;
} */

#box-lineage .id {
	color: var(--black);
	opacity: .7;
	font-size: 12px;
	margin-bottom: 5px;
}






#box-lineage .node .info .max-rank,
#box-lineage .node .info .pv,
#box-lineage .node .info .last-purchased-date,
#box-lineage .node .info .active-status,
#box-lineage .node .info .star-status {
	font-size: 13px;
	line-height: 1.4em;
}





#box-lineage .horizontal-line {
	background: linear-gradient(to right, #2B2937 50%, rgba(255, 255, 255, 0) 0%);
	background-position: top, right, bottom, left;
    background-repeat: repeat-x;
    background-size: 10px 1px;
}

#box-lineage .canvas-wrapper.has-mid:after {
	content: "";
	position: absolute;
	left: 50%; transform: translateX(-50%);
	background: linear-gradient(#2B2937 50%, rgba(255, 255, 255, 0) 0%);
	background-position: top, right, bottom, left;
    background-repeat: repeat-y;
    background-size: 1px 10px;
	width: 1px;
	height: 100%;
}















#box-lineage .node {
	box-shadow: 0px 10px 20px rgba(0,0,0,.1);
	display: flex;
	position: relative;
	z-index: 10;
	padding: 6px;
	border-radius: 15px;
}

#box-lineage .node .info-wrapper {
	background: var(--white);
	box-shadow: 0px 5px 15px rgba(0,0,0,.5);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;	
	position: relative;
	padding: 2px;
	border-radius: 12px;
}






/* #box-lineage .node.level-0, 
#box-lineage .node.level-0 .info .avatar {
	background-image: linear-gradient(#0086D0, #0046A3);
}

#box-lineage .node.level-1, 
#box-lineage .node.level-1 .info .avatar {
	background-image: linear-gradient(#FBBB3D, #FB873A);
}

#box-lineage .node.level-2,
#box-lineage .node.level-2 .info .avatar {
	background-image: linear-gradient(#BBCF52, #93CF61);
}

#box-lineage .node.level-x,
#box-lineage .node.level-x .info .avatar {
	background-image: linear-gradient(#FB873A, #F85A2D);
} */



#box-lineage .node.deleted-user {
	opacity: .4;
}


#box-lineage .node.deleted-user,
#box-lineage .node.deleted-user .info .avatar {
	background: #B1B1B1;
}








#box-lineage .node .info .avatar {
	position: relative;
	overflow: hidden;
	max-width: 60px;
	border-radius: 50%;
	padding: 2px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
}

#box-lineage .node .info .avatar img {
	border-radius: 50%;
	background: var(--white);
}


#box-lineage .node .info .avatar {
	position: relative;
	overflow: hidden;
	max-width: 60px;
	border-radius: 50%;
}


#box-lineage .node .info .pos, 
#box-lineage .node .info .pv,
#box-lineage .node .info .joint-date {
	font-size: 14px;
	line-height: 1.4em;
}




























#box-lineage .main-node {
	padding-bottom: 40px;
}



#box-lineage .main-node-info {
	padding: 40px 20px;
	border-radius: 10px;
	background: var(--white);
	display: inline-flex;
	box-shadow: 0px 10px 25px rgba(0,0,0,.1);
}


#box-lineage .node {
	text-align: center;
	position: relative;
}


#box-lineage .chart-module ::-webkit-scrollbar {
	height: 5px;
	background: rgba(0,0,0,.05);
	/* border-bottom-left-radius:  */
}









#box-lineage .chart-container {
	position: relative;
	overflow-x: auto;	
	overflow-y: hidden;
	background: var(--white);
}



#box-lineage .chart-container .chart-wrapper {
	position: absolute;
	top: 0;
	left: 0;
}









#box-lineage .node .info-wrapper {
	position: relative;
}


#box-lineage .node .info .user-id, 
#box-lineage .node .info .count-sub {
	width: 100%;
}


#box-lineage .node .info-wrapper .nationalFlag {
	position: absolute;
	top:-15px;
	left:50%;
	margin-left: -15px;
	width:30px;
	height: 30px;
}












































































.introduction-products .featured-slider .nav {
	background: none;
	width: 20px;
	height: 20px;
	transform: translateY(0);
}

.introduction-products .featured-slider .nav:hover {
	background: none;
}


.introduction-products .featured-slider .nav svg path {
	fill: rgba(36, 37, 41, .5);
	transition: all ease-in-out .3s;
}

.introduction-products .featured-slider .nav:hover svg path {
	fill: rgba(36, 37, 41, 1);
}



.introduction-products .featured-slider.on .nav svg {
	width: 100%;
	height: 100%;
}



@media all and (max-width: 991px) {
	.introduction-products .featured-slider .nav.prev {
		left: auto;
		right: calc(4% + 30px);
	}

	.introduction-products .featured-slider .nav.next {
		right: 4%;
	}
}

@media all and (min-width: 992px) {
	.introduction-products .featured-slider .nav.prev {
		left: auto;
		right: 30px;
	}

	.introduction-products .featured-slider .nav.next {
		right: 0;
	}
}










@media all and (max-width: 575px) {
	.introduction-products .featured-slider .nav {
		top: 16px;
	}
}

@media all and (min-width: 576px) and (max-width: 767px) {
	.introduction-products .featured-slider .nav {
		top: 16.5px;
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.introduction-products .featured-slider .nav {
		top: 19.5px;
	}
}

@media all and (min-width: 992px) {
	.introduction-products .featured-slider .nav {
		top: 22.5px;
	}
}













.faqs-content .info-heading p {
	margin-bottom: 0;
}

.faqs-content .info-heading.expanded p {
	font-weight: 500;
}













#qna .heading .dropdown-select {
	min-width: 140px;
	font-size: 14px;
}

#qna .heading .dropdown-select .selected span {
	display: flex;
	align-items: center;
}

#qna .heading .dropdown-select .selected {
	border-radius: 5px;
}


#qna .heading .dropdown-select .selected {
	background: var(--white);
	box-shadow: 0px 5px 10px rgba(0, 0, 0, .05);
	padding: 8px 15px;
}



@media all and (min-width: 576px) {
	#qna .heading .dropdown-select .selected .btn-open-sub {
		right: 15px;
	}
}













/*----- Notice Table -----*/

#qna .notices-table th:nth-child(1),
#qna .notices-table td:nth-child(1) {
	width: 50px;
	padding-left: 0;
	padding-right: 0;
}


#qna .notices-table th:nth-child(2),
#qna .notices-table td:nth-child(2) {
	text-align: left;
}


@media all and (max-width: 575px) {

	#qna .notices-table th:nth-child(2),
	#qna .notices-table td:nth-child(2) {
		padding-left: 10px;
	}
}


@media all and (min-width: 576px) {

	#qna .notices-table th:nth-child(2),
	#qna .notices-table td:nth-child(2) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

#qna .notices-table th:nth-child(3),
#qna .notices-table td:nth-child(3) {
	width: 110px;
	padding-left: 0;
	padding-right: 0;
}

#qna .notices-table th:nth-child(4),
#qna .notices-table td:nth-child(4) {
	width: 110px;
	padding-left: 0;
	padding-right: 0;
}

#qna .notices-table th:nth-child(5),
#qna .notices-table td:nth-child(5) {
	width: 110px;
	padding-left: 0;
	padding-right: 0;
}

@media all and (max-width: 575px) {
	#qna .notices-table tr {
		display: flex;
		flex-wrap: wrap;
	}

	#qna .notices-table th:nth-child(1),
	#qna .notices-table td:nth-child(1) {
		width: 50px;
		flex: 0 0 auto;
	}

	#qna .notices-table th:nth-child(2),
	#qna .notices-table td:nth-child(2) {
		flex: 1 0 0%;
	}

	#qna .notices-table th:nth-child(3),
	#qna .notices-table td:nth-child(3) {
		display: none;
	}

	#qna .notices-table th:nth-child(4),
	#qna .notices-table td:nth-child(4) {
		display: none;
	}

	#qna .notices-table th:nth-child(5),
	#qna .notices-table td:nth-child(5) {
		display: none;
	}

	#qna .notices-table td,
	#qna .notices-table th {
		vertical-align: top;
	}

	#qna .notices-table td:nth-child(2) a {
		margin-bottom: 3px;
	}
}




@media all and (max-width: 575px) {
	#qna .notices-table .mobile-info {
		display: flex;
		flex-wrap: wrap;
		opacity: .6;
		font-size: 13px;
	}
}


@media all and (min-width: 576px) {
	#qna .notices-table .mobile-info {
		display: none;
	}
}

#qna .notices-table .mobile-info span:not(:last-child) {
	margin-right: 10px;
}










#qna .notices-table tr {
	border-bottom: 1px solid rgba(0, 0, 0, .1);
}

#qna .notices-table tr:hover {
	cursor: pointer;
}

#qna .notices-table tr th,
#qna .notices-table tr td {
	white-space: nowrap;
}

#qna .notices-table tr td,
#qna .notices-table tr th {
	text-align: center;
}

@media all and (max-width: 575px) {

	#qna .notices-table tr td,
	#qna .notices-table tr th {
		text-align: center;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

@media all and (min-width: 576px) {

	#qna .notices-table tr td,
	#qna .notices-table tr th {
		text-align: center;
		padding-top: 12.5px;
		padding-bottom: 12.5px;
	}
}



/* #qna .notices-table tr td {
	position: relative;
} */






#qna .notices-table tr td .post-title {
	font-weight: 600;
	color: var(--russian-black);
	transition: all ease-in-out .3s;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;

}



#qna .notices-table tr td .post-title a {
	color: var(--russian-black);
}

#qna .notices-table tr td .post-title {
	text-align: left;
}

#qna .notices-table tr:hover td .post-title {
	color: #FB9B51;
}


#qna .notices-table tr td .post-download path {
	fill: #5F9FFF;
	transition: all ease-in-out .3s;
}

#qna .notices-table tr:hover td .post-download path {
	fill: #0148FF;
}

#qna .notices-table .post-date,
#qna .notices-table .post-author {
	white-space: nowrap;
}








@media all and (max-width: 991px) {
	#schedule .calendar {
		margin-bottom: 0;
	}

	#schedule .calendar-detail {
		border-bottom: none;
		margin-bottom: 0;
	}
}










#privacy .content,
#terms-of-service .content {
	padding-top: 30px;
}

#privacy .h-small,
#terms-of-service .h-small {
	margin-top: 30px;
}

#privacy .title {
	padding-bottom: 20px;
}

#privacy p {
	margin-bottom: 0;
}



#terms-of-service p {
	margin: 0;
}





@media (max-width: 991px) {
	#help-center .main-heading {
		display: none;
	}
}

@media (min-width: 992px) {
	#help-center .main-container {
		padding: 20px 0;
	}
}

#box-view-window #chart-container{
	width: 100vw !important;
	height: 100vh!important;
  }
  #box-view-window #chart-container{
	overflow-x: scroll !important;
	overflow-y: scroll !important;
  }





@media all and (max-width: 575px) {}

@media all and (min-width: 576px) and (max-width: 767px) {}

@media all and (min-width: 768px) and (max-width: 991px) {}

@media all and (min-width: 992px) {}


.loading-box{
	top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	z-index: 1999;
	position: fixed;
	border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.3);
}

/* loader */


.full-screen {
    position: fixed;
    position: -ms-page;
}
.spinner-three-bounce {
    width: 70px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    margin: auto;
    z-index: 2000;


}
.spinner-three-bounce  > div {
	width: 18px;
    height: 18px;
    background-color: var(--white);
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-three-bounce .bounce1  {
    animation-delay: -.32s;
}

.spinner-three-bounce .bounce2  {
    animation-delay: -.16s;
}


/* end loader */

  .hidden-box{
	display: none;
  }







  



@media all and (max-width: 575px) {
	#autoship-payment-status .office-search .field-date {
        width: 100%;
        margin-bottom: 5px;
    }
    #autoship-payment-status .office-search .submit-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #autoship-payment-status .office-search .submit-btn {
        width: calc(100% - 105px);
    }
}

@media all and (min-width: 576px) {
	#autoship-payment-status .office-search .field-date {
        margin-right: 1.6%;
    }
}


@media all and (min-width: 992px) {
    #autoship-payment-status .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#autoship-payment-status .office-search .date-input {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #autoship-payment-status .office-search .date-search {
        flex: 1 0 0%;
    }  
}







@media all and (max-width: 575px) {
	#auto-ship-registration .office-search .field-date {
        width: 100%;
        margin-bottom: 5px;
    }
    #auto-ship-registration .office-search .submit-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #auto-ship-registration .office-search .submit-btn {
        width: calc(100% - 105px);
    }
}

@media all and (min-width: 576px) {
	#auto-ship-registration .office-search .field-date {
        margin-right: 1.6%;
    }
}


@media all and (min-width: 576px) and (max-width: 767px) {
	
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#auto-ship-registration .office-search .field-date {
        order: 1;  
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 10px;      
    }
    #auto-ship-registration .office-search .field-classification {
        order: 2;
        width: 50%;
    }
    #auto-ship-registration .office-search .submit-wrapper {
        order: 3;
        width: 50%;
    }
}



@media all and (min-width: 992px) {
    #auto-ship-registration .office-search .field-date .label {
        width: auto;
        margin-right: 10px;
    }
    #auto-ship-registration .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#auto-ship-registration .office-search .date-search {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;        
    }

    #auto-ship-registration .office-search .date-input{
        flex: 1 0 0%;
        margin-right: 20px;
        margin-bottom: 0;      
    }  
}





