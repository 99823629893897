


/*------- 1. Benefit Details Inquiry -------*/
/*------- 2. Benefit Accounting Inquiry -------*/
/*------- 3. Benefit Sponsorship Details -------*/
/*------- 4. Benefit Statement -------*/
/*------- 5. Benefit Remittance Details -------*/
/*------- 6. Witholding Tax -------*/
/*------- 7. Benefit Calendar -------*/
/*------- 8. Benefit Analysis -------*/







/*------- 1. Benefit Details Inquiry -------*/


#benefit-details-inquiry .office-search .submit-btn {
    height: 40px;
}

@media all and (min-width: 992px) {
    #benefit-details-inquiry .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#benefit-details-inquiry .office-search .date-input {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #benefit-details-inquiry .office-search .date-search {
        flex: 1 0 0%;
    }    
}








/*------- 2. Benefit Accounting Inquiry -------*/


#benefit-accounting-inquiry .office-search .submit-btn {
    height: 40px;
}

@media all and (min-width: 992px) {
    #benefit-accounting-inquiry .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#benefit-accounting-inquiry .office-search .date-input {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #benefit-accounting-inquiry .office-search .date-search {
        flex: 1 0 0%;
    }    

    #benefit-accounting-inquiry .office-search .field-date input {
        width: 80px;
    } 
}








/*------- 3. Benefit Sponsorship Details -------*/

@media all and (max-width: 575px) {
	#benefit-sponsorship-details .office-search .col_1 {
        width: 100%;
        margin-bottom: 5px;
    }
    #benefit-sponsorship-details .office-search .submit-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #benefit-sponsorship-details .office-search .submit-btn {
        width: calc(100% - 105px);
    }
}

@media all and (min-width: 576px) {
	#benefit-sponsorship-details .office-search .col_1 {
        margin-right: 1.6%;
    }
}


@media all and (min-width: 992px) {
    #benefit-sponsorship-details .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#benefit-sponsorship-details .office-search .date-input {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #benefit-sponsorship-details .office-search .date-search {
        flex: 1 0 0%;
    }  
}








/*------- 4. Benefit Statement -------*/




/*------- 5. Benefit Remittance Details -------*/

@media all and (max-width: 575px) {
	#benefit-remittance-details .office-search .field-date {
        width: 100%;
        margin-bottom: 5px;
    }
    #benefit-remittance-details .office-search .submit-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #benefit-remittance-details .office-search .submit-btn {
        width: calc(100% - 105px);
    }
}

@media all and (min-width: 576px) {
	#benefit-remittance-details .office-search .field-date {
        margin-right: 1.6%;
    }
}


@media all and (min-width: 992px) {
    #benefit-remittance-details  .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#benefit-remittance-details  .office-search .date-input {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #benefit-remittance-details  .office-search .date-search {
        flex: 1 0 0%;
    }  
}





/*------- 6. Witholding Tax -------*/


@media all and (max-width: 575px) {
	#witholding-tax .office-search .field-date {
        width: 100%;
        margin-bottom: 5px;
    }
    #witholding-tax .office-search .submit-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #witholding-tax .office-search .submit-btn {
        width: calc(100% - 105px);
    }
}

@media all and (min-width: 576px) {
	#witholding-tax .office-search .field-date {
        margin-right: 1.6%;
    }
}


@media all and (min-width: 992px) {
    #witholding-tax  .office-search .field-date .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
	#witholding-tax  .office-search .date-input {
        flex: 0 0 auto;
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #witholding-tax  .office-search .date-search {
        flex: 1 0 0%;
    }  
}






