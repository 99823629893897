

/*------- Mobile Menu Toggle -------*/

.menu-toggle {
	display: flex;
	margin-right: 0;
	/* margin-left: auto; */
}

@media all and (max-width: 991px) {
	#mobile-toggle {
		display: flex;
		margin-left: auto;
	}	
	.menu-toggle {
		width: 100%;
		height: 30px;
	}
	.mobile-lang-switcher{
		display: none !important;
	}
}

@media all and (min-width: 992px) {
	#mobile-toggle {
		display: none;
	}		
	
}
	


#mobile-toggle {
	margin-top: auto;
	margin-bottom: auto;
	transition: all ease-in-out .3s;
	padding: 0;
}



@media all and (max-width: 575px) {
	#mobile-toggle {
		width: 30px; 
		height: 30px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#mobile-toggle {
		width: 32px; 
		height: 32px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#mobile-toggle {
		width: 34px; 
		height: 34px;
	}	
}


@media all and (max-width: 991px) {
	#main-header {
		background: white;
	}	
	#main-header .search-product .btn-search-open path {
		stroke: #454F5F;
	}	
	#main-header .search-product .btn-search-close path {
		fill: #454F5F;
	}
}

.row.main-menu > li{
	padding-left: 0;
	padding-right: 0;
}


#main-header ul {
	margin: 0;
	padding: 0;
}





#top-menu {	
	position: relative;
	color: #fff;
	font-size: 13px;
	
}
/* #top-menu::after {	
	content: '';
	position: absolute;
	bottom: 0px;
	left: 2%;
	width: 96%;
	border-bottom: 1px solid #EFEDF7;
} */


#top-menu .container {
	margin-left: auto !important;
	margin-right: auto !important;
	border-bottom: 1px solid #EFEDF7;
}


#top-menu a {	
	color: #2E3144;
	transition: all ease-in-out .3s;
}



#top-menu .container {
	padding-top: 0;
	padding-bottom: 0;
}

#top-menu li {
	display: block;
	position: relative;
	display: flex;
}
#top-menu .center-nav li {
	height: 15px;
	margin-top: 45px;
	margin-bottom: 40px;
}
#top-menu .center-nav li:not(:last-child) {
	border-right: 1px solid rgba(46, 49, 68, 0.2);
}


#top-menu a {
	/* padding: 0 15px; */
    /* padding: 0px 0px 0px 20px; */
	align-items: center;	
	text-transform: uppercase;	
}










/*------ Center Nav ------*/

#top-menu .center-nav .menu-item a {
	padding: 0 15px;
	color: #2E3144;
	opacity: 0.5;
}
#top-menu .center-nav .menu-item.active a, 
#top-menu .center-nav .menu-item:hover a {
	color: #2E3144;
	opacity: 1;
}











/**********************************/



#top-menu label {
	display: block;
	padding: 10px;	
}

#top-menu label span {
	color: #F05438;
}



#top-menu .color-part, 
#top-menu .solid-part {
	fill: rgba(255,255,255,.5);
	transition: all ease-in-out .3s;
}

#top-menu .menu-item a:hover .color-part, 
#top-menu .menu-item a:hover .solid-part {
	/*fill: #F05438;*/
	fill: #fff;
}










/*----- Menu Item Cart -----*/

#main-header .menu-item.cart .total-amount {
	margin-right: 10px;
}

#main-header .menu-item.cart .quantity {
	background: #F88125;
	border-radius: 25px;
	width: 20px;
	height: 20px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 10px;
	
}

#main-header .menu-item.cart .quantity span {
	display: flex;
	width: 100%; 
	height: 100%;
	justify-content: center;
	align-items: center;	
	font-size: 11px;
	line-height: 1em;
	color: #FFF;
}






/**********************************/


#top-menu a {
	display: flex;	
}

#top-menu a * {
	margin-top: auto;
	margin-bottom: auto;
}

#top-menu a .icon {
	display: flex;
	align-items: center;
	margin-right: 10px;
}


#top-menu label {
	display: block;
	padding: 10px;	
}


@media all and (max-width: 991px) {
	#top-menu {
		display: none;
	}
}























#main-menu {
	position: relative;
}















/*------- Mobile Logo -------*/


@media all and (max-width: 991px) {
	#mobile-logo {
		height: 30px;
	}		
	#mobile-logo img{
		height: 30px;
		margin-right: auto;
		width: auto;
	}

}

@media all and (min-width: 992px) {
	#mobile-logo {
		display: none;
	}		
	
}






/*------- Mobile Menu Toggle -------*/




@media all and (max-width: 575px) {
	.mobile-toggle {
		width: 30px; 
		height: 30px;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.mobile-toggle {
		width: 32px; 
		height: 32px;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.mobile-toggle {
		width: 34px; 
		height: 34px;
	}	
}




/*** CROSS BURGER MENU EFFECT ***/

.cross_hamburger_menu {
  	position: relative;
  	display: block;
  	box-sizing: border-box;
  	cursor: pointer;
  	clear: both;
	margin: auto;	
}


.cross_hamburger_menu {
	width: 24px;
	height: 50%;
}	



.mobile-toggle.opened .cross_hamburger_menu div:first-child {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 80%;
}
.mobile-toggle.opened .cross_hamburger_menu div:last-child {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	width: 80%;
}

.mobile-toggle.opened .cross_hamburger_menu div:not(:first-child):not(:last-child) {
  	opacity: 0; 
}



.cross_hamburger_menu div {
	position: absolute;
  	background: #454F5F;
  	width: 100%;
  	height: 2px;
  	transition: 0.3s cubic-bezier(0,.01,.52,.99);
  	transform: 0;
  	transform-origin: center;
}

.cross_hamburger_menu div:nth-child(1) {
	top: 0;
	transform-origin: top left;
}

.cross_hamburger_menu div:nth-child(2) {
	top: 50%; transform: translateY(-50%);
}

.cross_hamburger_menu div:nth-child(3) {
	bottom: 0;
	transform-origin: bottom left;
}













/*------- Mobile Menu -------*/




#mobile-menu {
	position: absolute;
	width: 100vw;
	right: 0;
	top: 100%;
	z-index: 600;
	display: flex;
	transition: all ease-in-out .5s;
	opacity: 1;
    z-index: 999;
}



#mobile-menu.closed {
	top: -150vh;
	opacity: 0;
}



@media all and (max-width: 575px) {
	#mobile-menu {
		height: calc(100vh - 61px);
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#mobile-menu {
		height: calc(100vh - 69px);
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	#mobile-menu {
		height: calc(100vh - 77px);
	}	
}









#mobile-menu .mobile-menu-container {
	width: 100%;
	margin-right: 0;
	margin-left: auto;
	background: #FFF;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}





#mobile-menu ::-webkit-scrollbar {
	display: none;
}








#mobile-menu ul {
	padding: 0;
	list-style: none;
}



#mobile-menu .mobile-toggle-off {
	display: flex;
	align-items: center;
	cursor: pointer;
}


#mobile-menu .btn-open-sub {
	display: flex;
	align-items: center;
	margin-right: 6px;
}


#mobile-menu .menu-item .level-1 span {
	font-size: 16px;
}


#mobile-menu .level-1 .btn-open-sub {
	margin-right: 0;
	margin-left: auto;
	display: flex;
	transition: all ease-in-out .3s;	
}

#mobile-menu .level-1 svg,
#mobile-menu .level-1 span {
	margin-top: auto;
	margin-bottom: auto;
}





#mobile-menu .level-1 span {
	font-size: 15px;
}

#mobile-menu .level-2 span {
	font-size: 14px;
}





/*--------- Mobile Top Menu ---------*/

#mobile-top-menu {
    background: #2A2E46;
}

#mobile-top-menu .container {
	padding: 0;
}

#mobile-top-menu .controls {
	margin-bottom: 25px;
}





/* nghi fix row have padding left and right base on core bootstrap 5.3*/
.mobile-sites-nav > .row{
	flex-wrap:nowrap !important;
	justify-content: start;
}
.mobile-sites-nav ul li > .row, .mobile-lang-switcher .row, #mobile-bottom-menu .row > *{
	--bs-gutter-x: 0;
}
/*------------*/


#mobile-top-menu .mobile-sites-nav ul li {
	flex: 0 0 auto;
    width: auto;    
    opacity: .5;
    transition: all ease-in-out .3s;
}

#mobile-top-menu .mobile-sites-nav ul li.active {
	opacity: 1;
}

#mobile-top-menu .mobile-sites-nav ul li a {
	align-items: center;  
    padding: 20px 10px;  
}






#mobile-top-menu .mobile-sites-nav ul li a .icon {
    margin-right: 5px;
} 

#mobile-top-menu .mobile-sites-nav ul li:not(.active) a .icon .dot-core {
    display: none;
} 

#mobile-top-menu .mobile-sites-nav ul li:not(.active) a .icon .dot-bg {
    stroke: rgba(255,255,255,.8);
    fill: none;
} 



#mobile-top-menu .mobile-sites-nav ul li a span {
	padding-top: 2px;
    color: #FFF;
}



@media all and (max-width: 575px) {
	#mobile-top-menu .mobile-sites-nav ul li:not(:last-child) {
        margin-right: 20px;
        padding: 0 10px;
    }
}


@media all and (min-width: 576px) and (max-width: 767px) {
	#mobile-top-menu .mobile-sites-nav ul li:not(:last-child) {
        margin-right: 25px;
    }
}


@media all and (min-width: 768px)  {
	#mobile-top-menu .mobile-sites-nav ul li:not(:last-child) {
        margin-right: 30px;
    }
}




#mobile-top-menu ul li:not(:last-child) a {
	border-right: 1px solid rgba(0,0,0,.1);
}



#mobile-top-menu ul li.active a {	
	color: rgba(255,255,255,1);
	font-weight: 600;
}










#mobile-top-menu .mobile-lang-switcher img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
}

#mobile-top-menu .mobile-lang-switcher .selected {
    color: #FFF;
    align-items: center;
    padding: 20px 0;
}

#mobile-top-menu .mobile-lang-switcher .selected span {
    padding-top: 2px;
}


#mobile-top-menu .mobile-lang-switcher .options {
    padding: 10px;
    background: #FFF;
    box-shadow: 0px 5px 15px rgba(0,0,0,.1);
    width: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
}

#mobile-top-menu .mobile-lang-switcher ul li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 0;
}


#mobile-top-menu .mobile-lang-switcher ul li:not(:last-child) {
    border-bottom: 1px solid #EAEBED;
}

#mobile-top-menu .mobile-lang-switcher .options span {
    color: #2A2E46;
    padding-top: 2px;
}














/*--------- Mobile Main Menu ---------*/

#mobile-main-menu {
	margin-bottom: 80px;
}

#mobile-main-menu .container {
	padding: 10px 0;
}


#mobile-main-menu .menu-item .level-1 {
	position: relative;
	padding-right:1rem ;
}

#mobile-main-menu .menu-item:not(:last-child) .level-1:after {
	content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #EAEBED;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}


#mobile-main-menu .menu-item .level-1 a span {
    color: #2A2E46;
    display: inline-block;
    position: relative;
    padding: 15px 10px;
}

#mobile-main-menu .menu-item.active .level-1 a span:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background: #2A2E46;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}






#mobile-main-menu .menu-item .level-1 span {
	color: #2A2E46;
}

#mobile-main-menu .menu-item .level-1 svg path {
	stroke: #2A2E46;
}



#mobile-main-menu .menu-item.active .sub-menu {
	position: relative;
}










/*--------- Mobile Bottom Menu ---------*/


#mobile-bottom-menu .container {
	border-top: 1px solid rgba(0,0,0,.1);
	padding: 10px 0;
}

#mobile-bottom-menu ul li:not(:last-child) {
	border-bottom: 1px solid rgba(0,0,0,.05);
}


#mobile-bottom-menu ul li a,
#mobile-bottom-menu ul li.hello {
	display: flex;
	flex-wrap: wrap;
	padding: 15px 20px;
}






#mobile-bottom-menu ul li a span {
	color: #242529;
}

#mobile-bottom-menu ul li a svg path {
	fill: #242529;
}




#mobile-bottom-menu ul li a .icon {
	order: 2 !important;
	margin-right: 0;
	margin-left: auto;
}

#mobile-bottom-menu ul li a .label {
	order: 1 !important;
	display: flex;
}

#mobile-bottom-menu ul li.cart .item-name {
	margin-right: 5px;
}
























/*--------- Sub Menu ---------*/

#mobile-main-menu .menu-item .btn-open-sub {
	transition: all ease-in-out .3s;
	transform: rotate(0deg);
}

#mobile-main-menu .menu-item .btn-open-sub.opened {
	transform: rotate(-135deg);
}


#mobile-main-menu .menu-item:not(.active) .sub-menu {
	display: none;
}





#mobile-main-menu .menu-item:last-child .sub-menu {
	border-top: 1px solid #EAEBED;
}


#mobile-main-menu .sub-menu {
	margin-bottom: 5px;
	/* background: rgba(146,165,239,.05); */
	padding: 15px 0;
	border-bottom: 1px solid #EAEBED;    
}

#mobile-main-menu .sub-menu-item a {
	display: block;
	padding: 5px 10px;
}




#mobile-main-menu .sub-menu-item.active a {
	opacity: 1;
}



#mobile-main-menu .sub-menu li a {
	color: #2A2E46;	
    font-weight: 400;
    opacity: .8;
}

#mobile-main-menu .sub-menu li.active a {
    opacity: 1;
	font-weight: 600;
}




#mobile-main-menu .sub-menu.closed {
	display: none;
}







/* Swal */
.swal2-container {
	z-index: 99999990 !important;
}

/* overlay loading */
.spinner-three-bounce {
	z-index: 100000000 !important;
}