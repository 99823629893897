
/* ----- NEW -----*/

/*------ Single Post --------*/







@media all and (max-width: 575px) {
	.single-post {
        padding-top: 25px;
        padding-bottom: 90px;
    }
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.single-post {
        padding-top: 30px;
        padding-bottom: 100px;
    }
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.single-post {
        padding-top: 35px;
        padding-bottom: 110px;
    }
}


@media all and (min-width: 992px) {
	.single-post {
        padding-top: 40px;
        padding-bottom: 120px;
    }
}












.single-post .post-header {
	border-bottom: 1px solid rgba(0,0,0,.1);
}






.single-post .post-header h2 {
	font-weight: 600;
	margin-bottom: 0;
}


.single-post .post-header .post-meta {
	display: flex;
}




@media all and (max-width: 575px) {
	.single-post .post-header {
		padding-bottom: 10px;
	}
}





@media all and (min-width: 576px) {
	.single-post .post-header {
		padding-bottom: 20px;
	}

	.single-post .post-header h2 {
		text-align: center;
	}
	.single-post .post-header .post-meta {
		justify-content: center;
	}
	.single-post .post-header .post-meta .info {
		border-right: 1px solid rgba(0,0,0,.1);
	}

}
















.single-post .post-header .post-meta .info {
	display: flex;
	align-items: center;
}


@media all and (max-width: 360px) {	
	.single-post .post-header h2 {
		font-size: 18px;
	}
	.single-post .post-header .post-meta .info {
		font-size: 13px;
	}
}


@media all and (max-width: 575px) {
	.single-post .post-header .post-meta .info {
		margin-bottom: 5px;
	}
	.single-post .post-header .post-meta .info:not(:last-child) {
		margin-right: 15px;
	}
	.single-post .post-header .post-meta .info img {
		margin-right: 5px;
	}
}


@media all and (min-width: 576px) {
	.single-post .post-header .post-meta .info {
		padding: 0 20px;
	}	
	.single-post .post-header .post-meta .info:first-child {
		padding-left: 0;
	}
	.single-post .post-header .post-meta .info:last-child {
		padding-right: 0;
		border-right: none;
	}
	.single-post .post-header .post-meta .info img {
		margin-right: 10px;
	}

}











@media all and (max-width: 575px) {
	.single-post .post-header .post-meta .info img {
		height: 16px;
		width: auto;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.single-post .post-header .post-meta .info img {
		height: 18px;
		width: auto;
	}
}


@media all and (min-width: 768px) {
	.single-post .post-header .post-meta .info img {
		height: 20px;
		width: auto;
	}
}


















.single-post .post-header .post-meta .info span {
	display: block;
}


.single-post .post-header .post-meta .info.download {
	cursor: pointer;
	color: #205EFF;
}





/*---- Post Body ----*/

.single-post .post-body {
	padding: 20px 0;
}

@media all and (max-width: 575px) {
	.single-post .post-body {
		padding: 25px 0;
	}
}


@media all and (min-width: 576px) and (max-width: 767px) {
	.single-post .post-body {
		padding: 30px 0;
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.single-post .post-body {
		padding: 35px 0;
	}
}


@media all and (min-width: 992px) {
	.single-post .post-body {
		padding: 40px 0;
	}
}





.single-post .post-body img {
	margin-bottom: 20px;
}



















/*---- Post Footer -----*/


.single-post .post-footer {
	border-top: 1px solid rgba(0,0,0,.1);
}


@media all and (max-width: 575px) {
	.single-post .post-footer {
		padding-top: 25px;
	}
}

@media all and (min-width: 576px) and (max-width: 767px) {
	.single-post .post-footer {
		padding-top: 30px;
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.single-post .post-footer {
		padding-top: 35px;
	}
}

@media all and (min-width: 992px) {
	.single-post .post-footer {
		padding-top: 40px;
	}
}




.single-post .post-footer a path {
	fill: none;
	stroke: #2B2937;
	stroke-linecap: round;	
	opacity: .5;
	transition: all ease-in-out .3s;
}

.single-post .post-footer a {
	display: flex;
	width: 50%;
}


.single-post .post-footer a h4 {
	padding-left: 2%;
	padding-right: 2%;
	color: #2B2937;
	transition: all ease-in-out .3s;
	margin-bottom: 0;
}


.single-post .post-footer a:hover h4 {
	color: #F05438;
}

.single-post .post-footer a:hover path {
	stroke: #F05438;
}



.single-post .post-footer .prev {
	justify-content: end;
	text-align: right;
	padding-right: .5em;
}	

.single-post .post-footer .next {
	padding-left: .5em;
}	



@media all and (max-width: 360px) {
	.single-post .post-footer a h4 {
		font-size: 15px;
	}
}


@media all and (min-width: 576px) {		
	.single-post .post-footer .next {
		border-left: 1px solid rgba(0,0,0,.1);
	}
}










.single-post .post-body h2 {
	margin-bottom: 1em;
}


.single-post .post-body p {
	margin-bottom: 0;
}


.single-post .post-body ul, 
.single-post .post-body ol {
	padding-left: 1em
}



.single-post .post-body ol ol, 
.single-post .post-body ol ul, 
.single-post .post-body ul ul, 
.single-post .post-body ul ol {
	margin-bottom: .5em;
}





















/*--- Notice Post ---*/

.notice-post .post-body {
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;
}


.notice-post .post-body img {
	display: block;	
	width: auto;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}



/*--- Video Post ---*/

.video-post .post-body video {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.video-post .post-body {
	padding: 10px 0;
}

.video-post .post-header {
    border-bottom: none;
}

.video-post .post-footer {
    border-top: none;
}






/*--- Gallery Post ---*/

.gallery-post .post-body {
	padding: 10px 0;
}

.gallery-post .post-header {
    border-bottom: none;
}

.gallery-post .post-footer {
    border-top: none;
}

