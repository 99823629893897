




.paths ul {
    list-style: none;
    margin-bottom: 0;
}

.paths ul li:not(:last-child) {
    margin-right: 10px;
}


@media all and (max-width: 767px) {
	.main-heading {
        padding: 15px 0;
    }
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.main-heading {
        padding: 17.5px 0;
    }
}

@media all and (min-width: 992px) {
	.main-heading {
        padding: 40px 0;
    }    
}



@media all and (min-width: 768px) {
	span.block {
		display: inline-block;
	}
}







