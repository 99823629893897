/**/



.login-page .heading {
    text-align: center;
}


.login-page .heading h2 {
    font-weight: bold;
}



.login-page .form input:not([type="checkbox"]) {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background: #F9FAFD;
    border: 1px solid #EAEBED;
}

.login-page .form .field:not(:last-child) {
    margin-bottom: 5px;
}
/*----- 2. Find ID and 1. Find Password -----*/

#find-password .form .selected, #find-id .form .selected {
    background: #F9FAFD;
    border: 1px solid #EAEBED;
    height: 40px;
    width: 100%;
    align-items: center;
}
#find-password .form .field-birthdate .years-select,#find-password .form .field-birthdate .months-select, #find-id .form .field-birthdate .years-select, #find-id .form .field-birthdate .months-select{margin-right:10px}

















