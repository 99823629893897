.registration-steps{list-style:none;padding:0}
@media all and (max-width: 575px) {
.registration-steps{margin-bottom:10px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.registration-steps{margin-bottom:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.registration-steps{margin-bottom:35px}
}
@media all and (min-width: 992px) {
.registration-steps{margin-bottom:40px}
}
.registration-steps li{text-align:center;width:33%}
.registration-steps li:not(:last-child){margin-right:.5%}
@media all and (max-width: 575px) {
.registration-steps li h2{display:block;padding:0 10px;padding-bottom:10px;border-bottom:3px solid #D4D5DA;margin-bottom:10px}
.registration-steps li.current h2{border-bottom:3px solid #2A2E46}
.registration-steps li.done h2{border-bottom:3px solid #44D08D;color:#44D08D}
}
@media all and (min-width: 576px) {
.registration-steps li{padding:0 10px;border-bottom:3px solid #D4D5DA}
.registration-steps li.current{border-bottom:3px solid #2A2E46}
.registration-steps li.done{border-bottom:3px solid #44D08D;color:#44D08D}
}
.registration-steps li.current h2,.registration-steps li.current p{opacity:1}
.registration-steps li p{line-height:1.25em}
@media all and (max-width: 575px) {
.registration-steps li p{font-size:12px}
}
.member-registration .step-content .inputs-wrapper{align-items:center}
@media only screen and (max-width: 767px) {
.member-registration .step-content .inputs-wrapper{margin-bottom:10px}
}
.member-registration .step-content .hyphen{margin-left:10px;margin-right:10px}
.member-registration .step-content{margin-bottom:10px}
.member-registration .step-content:last-child{margin-bottom:0}
@media all and (max-width: 575px) {
.registration-form{padding:30px 20px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.registration-form{padding:40px 30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.registration-form{padding:50px 40px}
}
@media all and (min-width: 992px) {
.registration-form{padding:60px 0}
}
.registration-form{margin-left:auto;margin-right:auto}
@media all and (max-width: 991px) {
.registration-form .form-row{margin-bottom:15px}
}
@media all and (min-width: 992px) {
.registration-form .form-row{margin-bottom:20px}
}
.registration-form .form-row:last-child{margin-bottom:0}
@media all and (max-width: 575px) {
.registration-form .label{margin-bottom:0}
}
@media all and (min-width: 576px) and (max-width: 991px) {
.registration-form .label{padding-right:15px}
}
@media all and (min-width: 992px) {
.registration-form .label{padding-right:20px}
}
.registration-form .dropdown-select .selected,.registration-form .dropdown-select .options{background:#FFF}
.registration-form .dropdown-select .selected,.registration-form input{box-shadow:0 5px 10px rgba(0,0,0,.05);border:none;background:#fff}
.member-registration input:read-only{color:rgba(43,41,55,.7)}
.registration-form .dropdown-select .options{padding:10px 0;border-top:none;box-shadow:0 5px 10px rgba(0,0,0,.05)}
.registration-form .dropdown-select .option{padding:2.5px 15px;transition:all ease-in-out .3s}
.registration-form .dropdown-select .option:hover{color:#ac998a}
@media all and (max-width: 419px) {
.registration-form .year-select{width:34%;margin-right:2%}
.registration-form .month-select{width:31%;margin-right:2%}
.registration-form .day-select{width:31%}
.registration-form .calendar-select{width:100%;margin-top:10px}
}
@media all and (min-width: 420px) {
.registration-form .year-select{width:25%;margin-right:2%}
.registration-form .month-select,.registration-form .day-select{width:23%;margin-right:2%}
.registration-form .calendar-select{width:23%}
}
.registration-form .verify-btn{display:flex;justify-content:center;align-items:center;width:auto;max-width:100%;box-shadow:0 5px 10px rgba(0,0,0,.05)}
.member-registration .input .verify-btn{margin-top:0;margin-left:10px}
@media all and (max-width: 767px) {
.member-registration .input .verify-btn{min-width:80px}
}
@media all and (min-width: 768px) {
.member-registration .input .verify-btn{min-width:100px}
}
@media all and (max-width: 767px) {
.registration-form .extra .verify-btn{margin-top:10px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.registration-form .extra .verify-btn{margin-left:10px}
}
@media all and (min-width: 992px) {
.registration-form .extra .verify-btn{margin-left:15px}
}
#page-heading ul{list-style:none}
@media all and (max-width: 767px) {
.registration-form input{font-size:14px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.registration-form input{font-size:15px}
}
@media all and (min-width: 992px) {
.registration-form input{font-size:16px}
}
@media all and (max-width: 991px) {
.registration-form .verify-btn{padding-top:10px;padding-bottom:10px}
.registration-form .dropdown-select .selected,.registration-form input{padding:10px 15px}
.registration-form .label{padding:10px 0}
}
@media all and (min-width: 992px) {
.registration-form .verify-btn{padding-top:9px;padding-bottom:9px}
.registration-form .dropdown-select .selected,.registration-form input{padding:10px 15px}
.registration-form .label{padding:10px 0}
}
.member-registration .step-content .notice{text-align:center}
.member-registration .step-content .required{margin-right:10px;color:#ac998a}
.required-field{color:red;margin-left:5px}
@media all and (max-width: 575px) {
.member-registration .step-content .notice{margin-bottom:25px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
.member-registration .step-content .notice{margin-bottom:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.member-registration .step-content .notice{margin-bottom:35px}
}
@media all and (min-width: 992px) {
.member-registration .step-content .notice{margin-bottom:20px}
}
.member-registration .agreement-part{margin-bottom:20px}
#member-registration-2 .section-content .container{padding-bottom:0}
#member-registration-2 .step-content{background:#F1F5FB}
#member-registration-2 .registration-form{max-width:750px}
#member-registration-2 .registration-form .name input,#member-registration-2 .registration-form .birthdate input{width:100%}
@media all and (max-width: 639px) {
#member-registration-2 .step-content .label,#member-registration-2 .step-content .input,#member-registration-2 .step-content .extra{width:100%;max-width:300px;margin-left:auto;margin-right:auto}
}
@media all and (min-width: 640px) {
#member-registration-2 .step-content .label,#member-registration-2 .step-content .extra{width:calc(50% - 150px)}
#member-registration-2 .step-content .input{width:300px}
#member-registration-2 .registration-form .verify-btn{margin-top:0;margin-left:10px}
}
#member-registration-3 .form .field .dropdown-select .selected{background:#FFF;border:1px solid #EAEBED;width:100%;height:40px}
#member-registration-4 .section-content .container{padding-bottom:0}
#member-registration-4 .step-content{background:#F1F5FB}
#member-registration-4 .registration-form h2{text-align:center;margin-bottom:40px}
@media all and (max-width: 575px) {
#member-registration-4 .registration-form h2{margin-bottom:25px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
#member-registration-4 .registration-form h2{margin-bottom:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
#member-registration-4 .registration-form h2{margin-bottom:35px}
}
@media all and (min-width: 992px) {
#member-registration-4 .registration-form h2{margin-bottom:40px}
}
#member-registration-4 .registration-form .row.btns a{min-width:120px}
#member-registration-4 .registration-form .recommender-info span{font-weight:500}
#member-registration-4 .registration-form .recommender-name span{color:#EF780B}
#member-registration-4 .registration-form .recommender-id span{color:#EF780B}
@media all and (max-width: 576px) {
#member-registration-4 .registration-form .recommender-info{text-align:center}
}
@media all and (min-width: 576px) {
#member-registration-4 .registration-form .recommender-name{text-align:right;padding-right:10px}
#member-registration-4 .registration-form .recommender-id{text-align:left;padding-left:10px}
}
.searching-form .memCheck .submit-btn{background:#0CA0A4;box-shadow:0 5px 10px red;border-radius:5px;padding:12px 17px}
.btns-wrapper .top-line-btn{margin-right:10px}
@media all and (max-width: 575px) {
.result-block.recommended-genealogy h2{margin-bottom:0}
.memnberTab{box-shadow:none}
}
@media all and (min-width: 768px) and (max-width: 991px) {
.memInfor .searching-form .name-browsing-btn{right:0}
}
@media all and (min-width: 992px) {
#member-inquiry-by-position .searching-result .date-selects .label,#member-inquiry-by-position .searching-result .standard-member-name .label{width:65px;margin-right:10px}
}
#member-inquiry-by-position .searching-result .results .member-list .hr-table{position:relative}
@media (max-width: 767px) {
#member-inquiry-by-position .searching-result .searching-form .label{width:75px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
#member-inquiry-by-position .searching-result .inquiry-classification,#member-inquiry-by-position .searching-result .standard-member-name{margin-bottom:15px}
#member-inquiry-by-position .searching-result .inquiry-classification,#member-inquiry-by-position .searching-result .rank,#member-inquiry-by-position .searching-result .standard-member-name,#member-inquiry-by-position .searching-result .member-name{width:calc(50% - 5px)}
#member-inquiry-by-position .searching-result .standard-member-name{margin-right:10px}
#member-inquiry-by-position .searching-form .submit-btn-wrapper{width:100%}
}
#member-inquiry-by-position .inquiry-classification .dropdown-select{min-width:140px}
@media all and (max-width: 575px) {
#member-inquiry-by-position .searching-result .standard-member-name{margin-bottom:10px}
.searching-form .form-row,.searching-result .searching-form .container{box-shadow:none}
.searching-form .display-stage .info .value{padding:0 5px}
.searching-form .display-stage .up-btn,.searching-result .btns-wrapper .submit-btn{margin-right:0}
#member-inquiry-by-position .searching-result .searching-form .criteria-classification{margin-bottom:10px}
#member-inquiry-by-position .searching-form .form-row .info:not(.info-radio,.date-selects,.non-background,.desktop-time-btns){margin-bottom:12.5px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
#member-inquiry-by-position .searching-result .inquiry-classification,#member-inquiry-by-position .searching-result .standard-member-name{margin-bottom:15px}
#member-inquiry-by-position .searching-result .searching-form .criteria-classification{margin-bottom:12.5px}
}
@media all and (min-width: 992px) {
#member-inquiry-by-position .searching-result .standard-member-name,#member-inquiry-by-position .searching-result .inquiry-classification{padding-right:20px}
.memnberTab .result-block{margin-bottom:0}
.memnberTab .pagination{margin-top:30px;justify-content:space-between}
.searching-result .content-row{margin-bottom:30px}
}
@media all and (min-width: 576px) and (max-width: 991px) {
#member-inquiry-by-position .searching-result .inquiry-classification,#member-inquiry-by-position .searching-result .standard-member-name{margin-bottom:12.5px}
#member-inquiry-by-position .searching-result .searching-form .criteria-classification{margin-bottom:12.5px}
.memSearch .searching-form .mobile-time-btns a{padding:0 20px}
}
@media all and (min-width: 992px) {
#member-inquiry-by-position .searching-result .searching-form .submit-btn{min-width:120px}
}
@media all and (max-width: 575px) {
#member-inquiry-by-position .searching-result .searching-form .form-row:nth-child(2) .label{margin-bottom:5px}
}
@media all and (min-width: 576px) and (max-width: 991px) {
#member-inquiry-by-position .searching-result .searching-form .form-row:nth-child(2) .label{margin-right:20px}
}
@media all and (min-width: 992px) {
#member-inquiry-by-position .searching-result .searching-form .form-row:nth-child(2) .label{margin-right:10px}
}
#member-inquiry-by-position .general-list table,#member-inquiry-by-position .member-list table{text-align:center}
@media all and (min-width: 992px) {
#member-inquiry-by-position .overall .column:first-child{width:32%;margin-right:30px}
}
@media all and (max-width: 575px) {
#member-inquiry-by-position .searching-result .searching-form .search-info{margin-bottom:15px}
#member-inquiry-by-position .searching-result .searching-form .search-info:last-child{margin-bottom:0}
}
@media all and (min-width: 576px) and (max-width: 767px) {
#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(1),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(2),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(3),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(4){margin-bottom:15px}
#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(1),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(3){padding-right:15px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(1),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(2){margin-bottom:15px}
#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(1),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(3),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(4){padding-right:15px}
}
@media all and (min-width: 992px) {
#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(1),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(2){margin-bottom:20px}
#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(1),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(3),#member-inquiry-by-position .searching-result .searching-form .search-info:nth-child(4){padding-right:20px}
}
@media all and (min-width: 992px) {
#box-lineage .searching-result .date-selects .label,#box-lineage .searching-result .standard-member-name .label{width:50px;margin-right:10px}
}
#box-lineage .section-content .container.results{background:#fff url(../../img/my-office/map.png) no-repeat center;padding:30px;box-shadow:0 5px 15px rgba(0,0,0,0.05);border-radius:25px;margin-top:30px}
#box-lineage .searching-result .criteria-classification .radios-group li:not(:last-child){margin-right:20px}
.table-container .col_data{background:transparent}
@media all and (max-width: 575px) {
#box-lineage .searching-result .display-stage{margin-right:10px}
#box-lineage .searching-result .standard-member-name,#box-lineage .searching-result .display-stage,#box-lineage .searching-result .rank{margin-bottom:10px}
#box-lineage .searching-result .criteria-classification{margin-bottom:15px}
#box-lineage .section-content .container.results{height:auto}
.searching-form .form-row .info:last-child{border:none}
#box-lineage .searching-result .searching-form .form-row .info:not(.info-radio,.date-selects,.non-background,.desktop-time-btns){border:none}
}
@media all and (max-width: 767px) {
#box-lineage .searching-result .searching-form .label{width:75px}
#box-lineage .searching-form .form-row .info-radio{margin-bottom:12.5px}
#box-lineage .searching-result .standard-member-name,#box-lineage .searching-result .display-stage,#box-lineage .searching-result .rank{margin-bottom:12.5px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
#box-lineage .searching-result .btns-wrapper .submit-btn,#box-lineage .searching-result .btns-wrapper .top-line-btn{width:calc(50% - 10px)}
#box-lineage .searching-form .form-row .info-radio{width:100%}
}
@media all and (min-width: 576px) and (max-width: 991px) {
#box-lineage .searching-result .standard-member-name,#box-lineage .searching-result .display-stage,#box-lineage .searching-result .rank{margin-bottom:15px}
#box-lineage .searching-result .criteria-classification{margin-right:15px}
#box-lineage .searching-result .btns-wrapper{margin-left:auto;margin-right:0}
.searching-form .form-row .info:last-child{border:none}
#box-lineage .searching-result .searching-form .form-row .info:not(.info-radio,.date-selects,.non-background,.desktop-time-btns){border:none}
}
@media all and (min-width: 768px) and (max-width: 991px) {
#box-lineage .searching-form .time-btns{margin-top:10px}
#box-lineage .searching-result .standard-member-name,#box-lineage .searching-result .display-stage{width:calc(50% - 5px)}
#box-lineage .searching-result .standard-member-name{margin-right:10px}
}
@media all and (max-width: 991px) {
#box-lineage .searching-result .inquiry-classification{margin-bottom:15px}
#box-lineage .searching-result .inquiry-classification .label{margin-right:20px}
}
@media all and (min-width: 992px) {
#box-lineage .searching-result .standard-member-name{padding-right:20px}
#box-lineage .searching-result .display-stage,#box-lineage .searching-result .rank{margin-right:20px}
#box-lineage .searching-result .display-stage,#box-lineage .searching-result .rank,#box-lineage .searching-result .criteria-classification{margin-right:20px}
#box-lineage .table-container{padding:30px 0}
#box-lineage .searching-result .display-stage,#box-lineage .searching-result .rank{margin-right:20px}
}
#box-lineage .btns-wrapper .top-line-btn,#line-lineage .btns-wrapper .top-line-btn{align-items:center;display:flex}
#box-lineage .box-graph .node{position:relative}
#box-lineage .box-graph .node .content{align-items:center;justify-content:center;min-height:120px;position:relative}
#box-lineage .dashed{background-size:5px 1px,1px 5px}
#box-lineage .box-graph .spacing{width:10%}
#box-lineage .row.main{justify-content:center}
#box-lineage .row.main .node{min-width:240px}
#box-lineage .node ul li{display:block;text-align:center}
#box-lineage .node ul{padding:15px 0;margin-bottom:5px}
#box-lineage .node .name{border-top-left-radius:10px;border-top-right-radius:10px}
#box-lineage .under .node .name{padding:10px 0}
#box-lineage .main .node .name{padding:12.5px 0}
#box-lineage .node .content{border-radius:10px;box-shadow:0 5px 10px rgba(0,0,0,.1)}
@media all and (max-width: 575px) {
#box-lineage .row.main{padding-bottom:40px;position:relative}
#box-lineage .row.main:after{content:"";position:absolute;bottom:0;left:50%;transform:translateX(-50%);width:1px;height:40px;background:linear-gradient(#000 50%,rgba(255,255,255,0) 0%);background-position:top,right;background-size:1px 5px}
#box-lineage .row.under .node{width:50%;margin-bottom:30px}
#box-lineage .row.under .node:nth-child(1),#box-lineage .row.under .node:nth-child(3){padding-right:10%}
#box-lineage .row.under .node:nth-child(2),#box-lineage .row.under .node:nth-child(4){padding-left:10%}
#box-lineage .row.under .node:nth-child(1) .dashed,#box-lineage .row.under .node:nth-child(3) .dashed{top:50%;right:0;transform:translateY(-50%);width:20%;height:1px}
#box-lineage .row.under .node:nth-child(2) .dashed,#box-lineage .row.under .node:nth-child(4) .dashed{top:50%;left:0;transform:translateY(-50%);width:20%;height:1px}
#box-lineage .row.under .node:nth-child(1) .dashed:after,#box-lineage .row.under .node:nth-child(3) .dashed:after{content:"";position:absolute;right:-4px;top:50%;transform:translateY(-50%);width:9px;height:9px;background:#000;border-radius:10px}
#box-lineage .row.under .node:nth-child(1) .content:before,#box-lineage .row.under .node:nth-child(3) .content:before{content:"";position:absolute;right:-4px;top:50%;transform:translateY(-50%);width:0;height:0;border-top:4px solid transparent;border-bottom:4px solid transparent;border-left:4px solid #000}
#box-lineage .row.under .node:nth-child(2) .content:before,#box-lineage .row.under .node:nth-child(4) .content:before{content:"";position:absolute;left:-4px;top:50%;transform:translateY(-50%);width:0;height:0;border-top:4px solid transparent;border-bottom:4px solid transparent;border-right:4px solid #000}
#box-lineage .row.under .node:nth-child(1):after,#box-lineage .row.under .node:nth-child(3):after{content:"";position:absolute;width:1px;height:calc(100% + 30px);top:-30px;right:0;background:#000;background:linear-gradient(#000 50%,rgba(255,255,255,0) 0%);background-position:top,right;background-size:2px 5px}
#box-lineage .box-graph .row.under:last-child .node:last-child:not(:nth-child(2n)):after{height:calc(50% + 30px)!important}
#box-lineage .box-graph .row.under:last-child .node:nth-last-child(2):not(:nth-child(2n)):after{height:calc(50% + 30px)!important}
}
@media all and (min-width: 576px) {
#box-lineage .row.under .node{width:20%}
#box-lineage .row.under .node:nth-child(1),#box-lineage .row.under .node:nth-child(3){margin-right:5%}
#box-lineage .row.under .node:nth-child(2){margin-right:10%;margin-right:10%}
#box-lineage .row.under{padding:50px 0;position:relative}
#box-lineage .row.main{padding-bottom:50px;position:relative}
#box-lineage .row.main:after{content:"";position:absolute;bottom:0;left:50%;transform:translateX(-50%);width:1px;height:50px;background:linear-gradient(#000 50%,rgba(255,255,255,0) 0%);background-position:top,right;background-size:1px 5px}
#box-lineage .row.under:before{content:"";position:absolute;top:0;left:50%;transform:translateX(-50%);width:80%;height:1px;background:linear-gradient(to right,#000 50%,rgba(255,255,255,0) 0%);background-position:top,right;background-size:5px 1px}
#box-lineage .row.under:not(:last-child):after{content:"";position:absolute;top:0;left:50%;transform:translateX(-50%);width:1px;height:100%;background:linear-gradient(#000 50%,rgba(255,255,255,0) 0%);background-position:top,right;background-size:1px 5px}
#box-lineage .row.under .nodes-wrapper:before{content:"";position:absolute;top:-4px;left:50%;transform:translateX(-50%);width:8px;height:8px;border-radius:10px;background:#000}
#box-lineage .row.under .node .content:before{content:"";position:absolute;top:-4px;left:50%;transform:translateX(-50%);width:0;height:0;border-left:4px solid transparent;border-right:4px solid transparent;border-bottom:4px solid #000}
#box-lineage .row.under .node .dashed{content:"";position:absolute;width:1px;height:50px;top:-50px;left:50%;transform:translateX(-50%);background:#000;background:linear-gradient(#000 50%,rgba(255,255,255,0) 0%);background-position:top,right;background-size:2px 5px}
}
#line-lineage .searching-result .office-block h4{margin-bottom:20px}
#line-lineage .searching-result .line-b.none{margin-right:0}
@media all and (min-width: 992px) {
#line-lineage .searching-result .rank{margin-right:0}
#line-lineage .searching-result .mem-leg .rank{padding-right:20px}
#line-lineage .searching-result .office-block.line-a{width:calc(50% - 20px);margin-right:20px}
}
@media all and (max-width: 575px) {
#line-lineage .searching-result .office-block{width:100%}
}
@media all and (min-width: 576px) and (max-width: 991px) {
#line-lineage .searching-result .office-block{width:100%}
}
@media all and (min-width: 767px) and (max-width: 991px) {
#line-lineage .result-block{width:100%}
}
@media all and (min-width: 992px) {
#affiliate-member-analysis .section-content .container{padding:0}
}
#affiliate-member-analysis .position-chart .hr-chart ul li{display:block;width:calc(100%/9)}
#affiliate-member-analysis .section-content table{text-align:center;font-size:14px}
/* #affiliate-member-analysis .combination-table .table-wrapper{min-width:640px} */
@media all and (max-width: 575px) {
#affiliate-member-analysis .section-content .result-block{margin-bottom:40px}
}
@media all and (min-width: 576px) and (max-width: 767px) {
#affiliate-member-analysis .section-content .result-block{margin-bottom:0;margin-top:0}
#affiliate-member-analysis .section-content .result-block.monthly-subscriber-acquisition{margin-top:30px}
}
@media all and (min-width: 768px) and (max-width: 991px) {
#affiliate-member-analysis .section-content .result-block{margin-bottom:50px}
.searching-form .date-select{width:47%}
#affiliate-member-analysis .section-content .combination-table,#affiliate-member-analysis .section-content .age-chart{width:100%}
}
@media all and (min-width: 992px) {
#affiliate-member-analysis .section-content .result-block.monthly-subscriber-acquisition{margin-top:30px}
}
#affiliate-member-analysis .monthly-subscriber-acquisition .table-container .col_data .table-wrapper{min-width:640px}
.no_use{color:red;text-decoration:line-through}
.no_use a{color:red!important}